import React, { useState } from 'react'
import { Table, Badge, Form } from 'react-bootstrap';
import PdfDownloadLink from '../../../components/PdfDownloadLink';
import ShortDateTime from '../../../components/ShortDateTime';
import { Link } from 'react-router-dom';
import ExportOrders from '../../../components/ExportOrders';
import { authenticationService } from '../../../_services/Authentication.Service';
import PagedQuery from '../../../components/PagedQuery';

export default () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [refreshToken, setRefreshToken] = useState(Math.random);
  const [selectedOrders, setSelectedOrders] = useState([]);

  const OrderStateBadge = ({ orderState }) => {
    switch (orderState) {
      case 0: return <Badge variant="warning">Pågående</Badge>
      case 1: return <Badge variant="info">Bekräftad</Badge>
      case 2: return <Badge variant="danger">Annulerad</Badge>
      case 3: return <Badge variant="success">Slutförd</Badge>
      default: return;
    }
  }

  var debounceTimer;
  function doSearch(search) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setSearch(search)
    }, 330)
  }

  function toggleOrderSelected(id) {
    if (authenticationService.isAdmin) {
      if (selectedOrders.includes(id))
        setSelectedOrders(selectedOrders.filter((e) => e !== id));
      else
        setSelectedOrders([...selectedOrders, id]);
    }
  }

  return (
    <div>
      <h1>Ordrar</h1>
      {authenticationService.isAdmin &&
        <div className="mb-2">
          <ExportOrders selectedOrders={selectedOrders} setSelectedOrders={setSelectedOrders} setRefreshToken={setRefreshToken} />
        </div>
      }

      <Form.Group>
        <Form.Label>Sök</Form.Label>
        <Form.Control onChange={({ target }) => doSearch(target.value)} />
      </Form.Group>

      <PagedQuery url="/orders" setData={setData} search={search} refreshToken={refreshToken}>
        <Table hover size="sm">
          <thead>
            <tr>
              <th>Återförsäljare</th>
              <th>Kundnamn</th>
              <th>Abonnemang</th>
              <th>Total månadskostnad</th>
              <th>Skapad</th>
              <th>Avtal</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((order, idx) => {
              return (
                <tr key={idx} onClick={() => { toggleOrderSelected(order.id) }} className={selectedOrders.includes(order.id) ? 'bg-dark text-light' : ''}>
                  <td>{order.reseller}</td>
                  <td><Link className="text-info" to={`/orders/${order.id}`}>{order.fullName}</Link> {' '}<OrderStateBadge orderState={order.state} /></td>
                  <td>{order.subscriptionName}</td>
                  <td>{order.totalFee} kr</td>
                  <td><ShortDateTime date={order.createdDate} time /></td>
                  <td><PdfDownloadLink url={`/orders/${order.id}/pdf`} name={`${order.id}.pdf`} /></td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </PagedQuery>
    </div>
  )
}