import React from 'react'
import { Helmet } from "react-helmet-async";

export default ({ title, children }) => {

  let internalTitle = "Teleco Orderportal";
  if (title !== undefined)
    internalTitle = `${title} - ${internalTitle}`;

  return (
    <Helmet>
      <title>{internalTitle}</title>
      {children}
    </Helmet>
  )
}