import { STORE } from "../api/endpoints";
import { apiService } from "./Api.Service";
import { serialize } from 'object-to-formdata';

export const shopService = {
  list,
  get,
  addProduct,
  deleteProduct,
};

async function list() {
  return await apiService.Get(STORE);
}

async function get(id) {
  return await apiService.Get(`${STORE}/${id}`);
}

async function addProduct(data) {
  var formData = serialize(data, {indices: true})
  return await apiService.Upload("/store", formData);
}

async function deleteProduct(id){
  return await apiService.Delete(`${STORE}/${id}`);
}