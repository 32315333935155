import React, { useState } from "react";
import { Navbar, Nav, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import { Link, Redirect } from "react-router-dom";
import { navigationService } from "../_services/Navigation.Service";
import { authenticationService } from "../_services/Authentication.Service";

const MyLink = ({ children, href }) => {
  return (
    <LinkContainer to={href}>
      <Nav.Link>
        {children}
      </Nav.Link>
    </LinkContainer>
  );
};

const LogoutLink = () => {
  const [clicked, setLogout] = useState(false);

  return clicked ? <Redirect to="/" /> :
    <Nav>
      <Nav.Link onClick={() => {
        authenticationService.logout().then(() => {
          setLogout(true);
        })
      }}>Logga ut</Nav.Link>
    </Nav>
}

export default ({ history }) => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Link to="/" href="/" className="navbar-brand">
          Orderportal
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            {navigationService.navigationLinks.map((link, idx) => (
              <MyLink href={link.href} key={idx}>
                {link.name}
              </MyLink>
            ))}
          </Nav>
          <Navbar.Text>
            Inloggad som {authenticationService.currentUserGivenName}
          </Navbar.Text>
          <LogoutLink />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
