import React, { useState } from "react";
import { Table, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { useQuery } from "react-query";

import { apiService } from "../../../_services/Api.Service";
import { subscriptionService } from "../../../_services/Subscription.Service";

const SubscriptionsTable = ({ onRowSelected, contextMenu = false }) => {

  const [dsubscriptions, setSubscriptions] = useState([])

  const { status, data: subscriptions = [], error } = useQuery(["subscriptions", dsubscriptions], async () => { return apiService.Get("/subscriptions") });

  return (
    <>
      {error && error}
      {status === "loading" && <Spinner />}
      {subscriptions &&
        <>
          <Table responsive hover size="sm">
            <thead>
              <tr>
                <th>Abonnemangsval</th>
                <th>Datapaket</th>
                <th>Månadsavgift</th>
                <th>Trafik</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((sub, idx) => (
                <ContextMenuTrigger
                  id={`context-menu`}
                  subid={sub.id}
                  key={idx}
                  renderTag="tr"
                  attributes={{
                    key: idx,
                    onClick: event => { onRowSelected(sub.id, event) }
                  }}
                  collect={(props) => { return { id: props.subid } }}>
                  <td>{sub.name}</td>
                  <td>{sub.dataPackage} GB</td>
                  <td>{sub.monthlyFee} kr</td>
                  <td style={{ minWidth: 200 }}>{sub.traffic}</td>
                </ContextMenuTrigger>
              ))}
            </tbody>
          </Table>

          {contextMenu &&
            <ContextMenu id={`context-menu`}>
              <MenuItem onClick={async (_, data) => {
                await subscriptionService.Delete(data.id);
                setSubscriptions(subscriptions.filter((v, i) => v.id !== data.id));
              }}>
                <FontAwesomeIcon icon={faTrashAlt} />{' '}
                Ta bort
              </MenuItem>
            </ContextMenu>
          }
        </>
      }
    </>
  )
}

export default ({ onRowSelected }) => {
  document.title = 'Abonnemang - Teleco Orderportal';
  return (
    <SubscriptionsTable onRowSelected={onRowSelected} contextMenu />
  )
};
