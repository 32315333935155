import React from "react";
import { Route, Switch, withRouter, Redirect, useLocation } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Container } from "react-bootstrap";

import Layout from "./components/Layout";
import { navigationService } from "./_services/Navigation.Service";
import { authenticationService } from "./_services/Authentication.Service";
import NotFound from "./components/NotFound";
import ApplicationLoader from "./components/ApplicationLoader";


const PrivateRoute = ({ comp: Component, children, exact, ...rest }) => {
  const location = useLocation();
  const currentUser = authenticationService.currentUserValue;

  //check that user has token
  if (!currentUser || !currentUser.token) {
    return <Redirect to={{ pathname: '/login', state: { referrer: location.pathname } }} />
  }

  if (authenticationService.tokenExpired) {
    return <Redirect to={{ pathname: '/login', state: { referrer: location.pathname, tokenExpired: true } }} />
  }


  return (
    <Layout>
      <ApplicationLoader>
        <Route {...rest} render={props => {
          return (
            <Component {...props}>
              {children}
            </Component>
          )
        }} />
      </ApplicationLoader>
    </Layout>
  )
}


const FourOhFourHandler = () => {
  let it = <Route component={NotFound} />
  var currentUser = authenticationService.currentUserValue;
  return (currentUser && currentUser.access_token) ? <Layout>{it}</Layout> : <Container>{it}</Container>;
}

export const MySwitch = (location) => {
  return (
    <>
      <Switch>
        {navigationService.publicRoutes.map((route, idx) => (
          <Route exact={route.exact ?? false} path={route.path} key={idx} component={route.component} />
        ))}

        {navigationService.myRoutes.map((route, idx) => (
          <PrivateRoute exact={route.exact ?? false} path={route.path} key={idx} comp={route.component} />
        ))}

        <FourOhFourHandler />
      </Switch>
    </>
  );
};

MySwitch.propTypes = {
  location: PropTypes.object.isRequired,
};

export const AppRoutes = withRouter(MySwitch);
