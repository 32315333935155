import React, { useEffect, useContext, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { formDefaults } from "../_services/DefaultValues.Service";

const MySpinner = ({ loading }) => {
  return loading ? (
    <Spinner animation="border" role="status" size="sm" className="ml-2">
      <span className="sr-only">Loading...</span>
    </Spinner>
  ) : null;
}

const CreditCheckForm = ({ onSubmit, context }) => {
  const { state } = useContext(context);
  const [validated] = useState(false);
  const { register, handleSubmit, errors, watch, reset } = useForm({
    defaultValues: formDefaults.creditCheck
  });

  const watchConcent = watch("concent");
  const watchSsn = watch("ssn");

  useEffect(() => {
    if (!state.ssnLocked)
      reset();
  }, [state.ssnLocked, reset]);

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label>
          <strong>Personnummer</strong>
        </Form.Label>
        <Form.Control
          type="text"
          name="ssn"
          autoComplete="off"
          placeholder="yyyymmddxxxx"
          isInvalid={!!errors.ssn}
          // isInvalid={!errors.ssn}
          disabled={state.isLoading || state.ssnLocked}
          ref={register({ required: true, maxLength: 12, minLength: 12 })}
        />
      </Form.Group>

      {!state.ssnLocked &&
        <>

          {watchSsn && watchSsn.length === 12 &&
            <div>
              <Form.Text>
                <i>
                  När du tar en Kreditupplysning på privatperson måste legitimt skäl
                  finnas och du måste underrätta den omfrågade om att du gör det. Du
                  begär nu en Kreditupplysning på detta personnummer och det kommer att
                  genereras en kopia till den omfrågade. I kopian kommer det stå att ni
                  har tagit en upplysning på den omfrågade, samt vilken information som
                  använts i bedömningen av deras kreditvärdighet.
                </i>
              </Form.Text>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  name="concent"
                  label="Jag godkänner"
                  disabled={state.isLoading}
                  ref={register}
                />
              </Form.Group>
            </div>
          }

          <div>
            <Button
              variant="primary"
              type="submit"
              disabled={!watchConcent || state.isLoading}
            >
              Genomför kreditupplysning
          <MySpinner loading={state.isLoading} />
            </Button>
          </div>
        </>
      }
    </Form>
  )
}

export default CreditCheckForm;
