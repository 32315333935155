import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { userService } from "../../../_services/User.Service";
import ConfirmButton from "../../../components/ConfirmButton";
import { useQuery } from "react-query";
import { apiService } from "../../../_services/Api.Service";
import MyHelmet from "../../../components/MyHelmet";

export default ({ userId }) => {
  const { id } = useParams();
  const history = useHistory();
  const [refreshToken, setRefreshToken] = useState(Math.random());

  const { status, data: user = {}, error: fetchUserError, refetch } = useQuery([`user-${userId}`, id], async () => userService.getUser(userId), { manual: true });

  const { register, handleSubmit, errors, setError, reset } = useForm();
  const isCreating = id === undefined

  // fetch user data is not creating a new user
  if (!isCreating) {
    useEffect(() => {
      const getData = async () => {
        reset(await refetch());
      }
      getData();
    }, [refreshToken, reset, refetch]);
  }

  const validateFormAndSubmit = async (data) => {
    //Creating
    if (isCreating) {
      if (data.password !== data.passwordrepeat) {
        setError("passwordmatch", "notMatch", "Lösenordet matchar inte");
        return;
      }

      await apiService.Post("/users", {
        userName: data.userName,
        reseller: data.reseller,
        fullName: data.fullName,
        city: data.city,
        password: data.password
      });
    }
    //Updating
    else {
      if ((data.password || data.passwordrepeat) && data.password !== data.passwordrepeat) {
        setError("passwordmatch", "notMatch", "Lösenordet matchar inte");
        return;
      }

      await apiService.Put(`/users/${data.id}`, {
        reseller: data.reseller,
        fullName: data.fullName,
        city: data.city,
        password: data.password
      });
    }

    history.replace("/users")
  }

  const deleteUser = async () => {
    await apiService.Delete(`/users/${id}`);
    history.replace("/users")
  }

  const toggleAdmin = async () => {
    await apiService.Post(`/users/${id}/toggleadmin`);
    setRefreshToken(Math.random());
  }

  const lockoutUser = async () => {
    await apiService.Post(`/users/${id}/lockout`);
    setRefreshToken(Math.random());
  }

  return (
    <>
      {user?.disabled &&
        <Alert variant="info">
          <Alert.Heading>Utlåst användare</Alert.Heading>
          <p>Annändaren är utlåst och kommer inte kunna logga in eller använda Orderportalen.</p>
        </Alert>
      }
      <Form onSubmit={handleSubmit(validateFormAndSubmit)}>
        {fetchUserError && <>{fetchUserError.message}</>}
        {status === "loading" && <div>Loading</div>}
        {user && (
          <>
            <MyHelmet title={`${user?.id ? (`Användare ${user.id}`) : 'Ny användare'}`} />
            <input name="id" type="hidden" ref={register} />
            <Form.Group>
              <Form.Label>Epostadress</Form.Label>
              <Form.Control name="userName" size="sm" type="email" ref={register({ required: true })} disabled={user.userName} />
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={3}>
                <Form.Label>Återförsäljare</Form.Label>
                <Form.Control type="text" name="reseller" ref={register({ required: true })} />
                {errors.reseller && <span className="text-danger">This field is required</span>}
              </Col>
              <Col sm={6}>
                <Form.Label>Namn</Form.Label>
                <Form.Control type="text" name="fullName" ref={register({ required: true })} />
                {errors.fullName && <span className="text-danger">This field is required</span>}
              </Col>
              <Col sm={3}>
                <Form.Label>Ort</Form.Label>
                <Form.Control type="text" name="city" ref={register} />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row}>
              <Form.Label column sm="2">Lösenord</Form.Label>
              <Col sm="10">
                <Form.Control name="password" size="sm" type="password" ref={register()} />
                {errors.password && <span className="text-danger">This field is required</span>}
              </Col>

              <Form.Label column sm="2">Upprepa lösenord</Form.Label>
              <Col sm="10">
                <Form.Control name="passwordrepeat" size="sm" type="password" ref={register()} />
                {errors.passwordmatch && <span className="text-danger">{errors.passwordmatch.message}</span>}
              </Col>
            </Form.Group>


            <Button size="sm" className="mr-2" type="submit">Spara</Button>
            <Button size="sm" className="mr-2" variant="secondary" onClick={history.goBack}>Ångra</Button>
            <Button size="sm" className="mr-2" variant="warning" onClick={async () => await lockoutUser()}>{user.disabled ? 'Ta bort lås' : 'Lås ut'}</Button>
            {!isCreating &&
              <div className="float-right">
                <ConfirmButton size="sm" variant={user.roles?.includes("Admin") ? "danger" : "outline-danger"}
                  onClick={async () => await toggleAdmin()} text={user.roles?.includes("Admin") ? "Ta bort adminroll" : "Gör till admin"} />
                {' '}
                <ConfirmButton size="sm" onClick={async () => await deleteUser()} />
              </div>
            }
          </>
        )}
      </Form>
    </>
  )
}
