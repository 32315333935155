import React from "react";
import { Col, Row, Image, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { shopService } from "../../_services/Shop.Service";
import { baseUrl } from "../../_services/ApiEndpoints";
import { useQuery } from 'react-query';

export default () => {
  // const products = TestProducts;
  let history = useHistory();


  const { data: products, isLoading, error } = useQuery([`products`], shopService.list);

  return (
    <>
      {error && <span>Någonting gick fel.</span>}
      {isLoading ? <div style={{ display: "flex", justifyContent: "center", paddingTop: 50 }}><Spinner animation="border" role="status" size="lg" /></div> :
        <Row xs={1} sm={1}>
          {products && products.map && products.map((p, idx) => {
            return (
              <Col
                key={idx}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(`/shop/${p.id}`);
                }}
              >
                <div>
                  <strong>{p.name}</strong>
                  <p>{p.offerText}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      }
    </>
  );
};
