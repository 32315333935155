import React, { useState, useEffect } from "react"
import PdfDownloadLink from "./PdfDownloadLink";
import { apiService } from "../_services/Api.Service";

export default React.memo(({ orderId }) => {
    var [pdf, setPdf] = useState();

    useEffect(() => {
        async function load() {
            var { data, error } = await apiService.GetFile(`/orders/${orderId}/pdf`);
            if (!error) {
                var file = new File([data], `${orderId}.pdf`, { type: 'application/pdf' })
                let objUrl = window.URL.createObjectURL(file);
                setPdf({ url: objUrl, orderId });
            }
        }
        load();
    }, [orderId])

    if (!pdf)
        return <div>Inget abonnemangsavtal hittades.</div>

    return (
        <>
            <div>
                <h5>Avtalsdokument <PdfDownloadLink url={`/orders/${orderId}/pdf`} name={`${orderId}.pdf`} /></h5>

                <object
                    data={pdf.url}
                    width="100%"
                    height="600px"
                    name={`${orderId}.pdf`}
                >Laddar pdf</object>
            </div>
        </>
    );
});