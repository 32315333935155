import React, { useState, useEffect } from 'react';
import { shopService } from '../../_services/Shop.Service';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { Form, Col, Button } from 'react-bootstrap';
import { subscriptionService } from '../../_services/Subscription.Service';

function toNumberString(input) {
  return parseFloat(input).toLocaleString('se').replaceAll(',', ' ');
}

const SubChoices = React.memo(({ subs }) => {
  const { register } = useFormContext();

  return (
    <>
      <h6>Valbara abonnemang</h6>
      {subs.map((sub, idx) => {
        return (
          <div key={idx}>
            <input
              type="checkbox"
              id={'sub_' + sub.id}
              defaultChecked={sub.selected}
              name={`allowedSubscriptionIds`}
              ref={register}
              value={sub.id}
            />
            <label htmlFor={'sub_' + sub.id}>{`${sub.name} - ${toNumberString(sub.monthlyFee)} kr/mån`}</label>
          </div>
        );
      })}
    </>
  );
});

export default ({ productId, onCreated, onCancel }) => {
  // const [selectedImageUrl, setSelectedImageUrl] = useState();
  const [validated, setValidated] = useState();
  const [allSubs, setAllSubs] = useState([]);
  const [loading, setLoading] = useState(productId > 0);

  // const {register, handleSubmit, setValue, reset} = useForm();
  const formMethods = useForm({ mode: 'onChange' });
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  useEffect(() => {
    async function load() {
      var subscriptions = await subscriptionService.Get();

      if (productId > 0) {
        var product = await shopService.get(productId);
        setLoading(false);

        reset(product);
      }

      let listOfSubs = [];
      for (let i = 0; i < subscriptions.length; i++) {
        let sub = subscriptions[i];
        listOfSubs.push({
          id: sub.id,
          name: sub.name,
          monthlyFee: sub.monthlyFee,
          selected: product?.allowedSubscriptionsIds.includes(sub.id) ?? false,
        });
      }

      setAllSubs(listOfSubs);
    }
    // register({name: "image", required: true });
    load();
  }, [productId, reset]);

  async function onSubmit(data, e) {
    let form = e.target;

    if (form.checkValidity()) {
      setValidated(true);
      try {
        var addResult = await shopService.addProduct(data);
        onCreated(addResult);
      } catch (ex) {}
    }
  }

  const NewProductForm = (
    <div style={{ border: '1px solid', borderRadius: '3px', padding: '10px' }}>
      <h6>{productId > 0 ? 'Ändra hårdvara ' : 'Ny hårdvara'}</h6>

      {loading ? (
        <>Loading ... </>
      ) : (
        <FormProvider {...formMethods}>
          <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
            {productId !== 0 && <input type="hidden" name="id" ref={register} value={productId} />}
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>Produktnamn</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.name}
                    name="name"
                    required
                    ref={register({ required: true })}
                    placeholder="iPhone 10"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Produktpris</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.price}
                    name="price"
                    required
                    ref={register({ required: true, pattern: /^[0-9]{1,}?$/ })}
                    placeholder="7999"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Förhöjd avgift</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.abo}
                    name="abo"
                    required
                    ref={register({ required: true, pattern: /^[0-9]{1,}?$/ })}
                    placeholder="249"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Listpris</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.listPrice}
                    name="listPrice"
                    ref={register({ required: false, pattern: /^[0-9]{1,}?$/ })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <SubChoices subs={allSubs} />
              </Col>
            </Form.Row>
            {/* <Form.Row>
                        <Col>
                            <AdditionalProducts />
                        </Col>
                    </Form.Row> */}
            <Form.Row>
              <Col>
                <Button size="sm" variant="success" type="submit">
                  Spara
                </Button>{' '}
                <Button size="sm" variant="danger" onClick={onCancel}>
                  Avbryt
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </FormProvider>
      )}
    </div>
  );

  return NewProductForm;
};
