import React, { useState, useEffect } from 'react'
import { apiService } from '../_services/Api.Service';
import { Pagination, Form } from 'react-bootstrap';
import { usePaginatedQuery } from 'react-query';


export default ({ url, setData, children, search, refreshToken }) => {
  const pagepadding = 4;
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);

  // Reset page on search change
  useEffect(() => {
    first();
  }, [search])

  const internalUrl = `${url}?pageNumber=${page}&pageSize=${pageSize}&search=${search}`;

  usePaginatedQuery(
    [`pagedtable-${internalUrl}`, refreshToken, page, pageSize, search],
    async () => await apiService.Get(`${internalUrl}`),
    {
      onSuccess: async (data, variables) => {
        setMaxPage(data.pages)
        setTotalCount(data.totalCount)
        await setData(data.data)
      }
    }
  );

  const first = () => setPage(1);
  const previous = () => setPage(page - 1);
  const next = () => setPage(page + 1);
  function goto(pageNumber) {
    pageNumber = Math.max(pageNumber, 1);
    pageNumber = Math.min(maxPage, pageNumber)
    setPage(pageNumber);
  }
  const last = () => setPage(maxPage);

  let pages = [];
  let counter = -pagepadding;
  do {
    let number = Math.max(page + counter++, 0);
    if (number <= 0) continue;
    pages.push(<Pagination.Item key={number} onClick={() => setPage(number)} active={number === page}>{number}</Pagination.Item>)
  } while (counter < pagepadding + 1 && page + counter <= maxPage)

  return (
    <>
      {children}

      <div>Sida {page} av {maxPage} - totalt {totalCount} resultat</div>
      {pages &&
        <Pagination size="sm" className="float-left pr-2">

          <Pagination.First onClick={() => first()} disabled={page === 1} />
          <Pagination.Prev onClick={() => previous()} disabled={page === 1} />
          {page - pagepadding > 1 && <Pagination.Ellipsis onClick={() => goto(page - 2 * pagepadding - 1)} />}

          {pages}

          {page + pagepadding < maxPage && <Pagination.Ellipsis onClick={() => goto(page + 2 * pagepadding + 1)} />}
          <Pagination.Next onClick={() => next()} disabled={page === maxPage} />
          <Pagination.Last onClick={() => last()} disabled={page === maxPage} />

        </Pagination>
      }

      <div className="float-left">
        <Form.Control as="select" defaultValue={25} size="sm" onChange={({ target }) => { setPageSize(target.value); first(); }}>
          <option>1</option>
          <option>2</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
          <option>200</option>
          <option>500</option>
        </Form.Control>
      </div>
    </>
  )
}