let formDefaults = {
    auth: {
        username: "admin@teleco.se",
        password: "ChangeMe123"
    },
    creditCheck: {
        ssn: "121212121212",
        concent: true,
        contactNumber: "123123123"
    },
    shop: {
        ssn: "123123123123"
    }
}

if (process.env.NODE_ENV !== "development") {
    formDefaults = {}
}

export {
    formDefaults
}