import React from "react"
import { Table, Button, Spinner } from "react-bootstrap";
import { shopService } from "../../_services/Shop.Service";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from "react-query";

export default ({ onEdit }) => {
    // const [products, setProducts] = useState([]);

    const { data: products = [], isError, isLoading, failureCount, refetch } = useQuery(['productlist'], shopService.list);

    // useEffect(() => {
    //     async function load() {
    //         let prods = await shopService.list();
    //         setProducts(prods);
    //     }
    //     load();
    // }, []);

    async function onDelete(product) {
        if (window.confirm(`Är du säker att du vill ta port '${product.name}'`)) {
            await shopService.deleteProduct(product.id);
            refetch();
        }
    }

    const ThisTable = ({ children }) => {
        return <>
            <Table size="sm" responsive hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Namn</th>
                        <th>Pris</th>
                        <th>Förhöjd avgift</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </Table>
        </>
    }

    if (isLoading) return (
        <div style={{ display: "flex", justifyContent: "center", paddingTop: 50 }}>
            <Spinner animation="border" role="status" size="lg" >
                <span className="sr-only">Loading... {failureCount}</span>
            </Spinner>
        </div>
    )

    if (isError) return (
        <div>Någonting gick fel ...</div>
    )

    return (
        <>
            <ThisTable>
                {products.map((p, idx) => {

                    const row =
                        <tr key={idx}>
                            <td>{p.id}</td>
                            <td>{p.name}</td>
                            <td>{p.price}</td>
                            <td>{p.abo}</td>
                            <td style={{ textAlign: "right" }}>
                                <Button variant="link" size="sm" onClick={() => onEdit(p.id)}><FontAwesomeIcon icon={faEdit} /></Button>
                                <Button variant="link" size="sm" onClick={() => onDelete(p)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                            </td>
                        </tr>

                    return row;
                })}
            </ThisTable>
        </>
    )
}