import { USER_ENDPOINT, USERS_ENDPOINT } from "../api/endpoints";
import { apiService } from "./Api.Service";


export const userService = {
  createUser,
  getUser: GetUser,
  getUsers: Getusers
};

async function Getusers() {
  return await apiService.Get(USERS_ENDPOINT);
}

async function GetUser(username) {
  return await apiService.Get(USER_ENDPOINT(username));
}

async function createUser(data) {
  return await apiService.Post(USERS_ENDPOINT, data);
}