import AuthContainer from "../containers/AuthContainer";
import CreditCheckContainer from "../containers/CreditCheckContainer";
import SubscriptionsContainer from "../containers/admin/subscriptions/SubscriptionsContainer";
import UserContainer from "../containers/admin/users/UserContainer";
import BlacklistContainer from "../containers/admin/BlacklistContainer";
import OrderContainer from "../containers/admin/orders/OrderContainer";

import { authenticationService } from '../_services/Authentication.Service'
import PdfContainer from "../containers/admin/PdfContainer";

import ShopContainer from "../containers/admin/shop/ShopContainer";
import ShopApp from "../shopapp/ShopApp";

const NavLinks = [
  { href: "/subscriptions", name: "Abonnemang", component: SubscriptionsContainer, roles: ["Admin"] },
  { href: "/orders", name: "Ordrar", component: OrderContainer },
  { href: "/users", name: "Användare", component: UserContainer, roles: ["Admin"] },
  { href: "/blacklist", name: "Spärrlista", component: BlacklistContainer, roles: ["Admin"] },
  { href: "/pdf", name: "Avtal", component: PdfContainer, roles: ["Admin"] },
  { href: "/admin/shop", name: "Shop", component: PdfContainer, roles: ["Admin"] }
]

const PublicRoutes = [
  { path: "/login", component: AuthContainer },
  { path: "/shop", component: ShopApp }
];

const ProtectedRoutes = [
  { path: "/", component: CreditCheckContainer, exact: true },

  { path: "/subscriptions", component: SubscriptionsContainer, roles: ["Admin"] },
  { path: "/orders", component: OrderContainer },
  { path: "/users", component: UserContainer, roles: ["Admin"] },
  { path: "/blacklist", component: BlacklistContainer, roles: ["Admin"] },
  { path: "/pdf", component: PdfContainer, roles: ["Admin"] },
  { path: "/admin/shop", component: ShopContainer, roles: ["Admin"] }
];

const navigationService = {
  get myRoutes() { return ProtectedRoutes.filter(filterRoles) || []; },
  get publicRoutes() { return PublicRoutes || []; },
  get navigationLinks() { return NavLinks.filter(filterRoles) || []; }
};

function filterRoles(route) {
  if (route.roles === undefined || route.roles.length === 0) return true;

  let myRoles = authenticationService?.currentUserValue?.roles || [];
  let routeRoles = route.roles || [];

  let result = myRoles.some(role => routeRoles.includes(role))

  return result;
}

export {
  NavLinks,
  navigationService
}
