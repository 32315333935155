import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import ProductGrid from "./ProductGrid";
import ProductDetails from "./ProductDetails";
import { createBrowserHistory } from "history";
import "./Shop.scss";

export const shopHistory = createBrowserHistory();

const ShopRoutes = ({ match }) => {
  return (
    <Router history={shopHistory}>
      <Switch>
        <Route path={"/shop"} exact={true} component={ProductGrid} />
        <Route path={"/shop/:id"} component={ProductDetails} />
        <Route render={() => <h1>Not found</h1>} />
      </Switch>
    </Router>
  );
};

export default ShopRoutes;
