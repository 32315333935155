import { baseUrl } from "./ApiEndpoints"
import { apiService } from "./Api.Service";

const storeUrl = `/store`

async function list() {
    return await apiService.Get(`${storeUrl}`)
}

async function get(id) {
    return await apiService.Get(`${storeUrl}/${id}`)
}
async function createOrder(data) {
    return await apiService.Post(`${storeUrl}/order`, data);
}

async function confirmOrder(orderId){
    return await apiService.Post(`${storeUrl}/order/confirm`, {orderId: orderId});
}

export const shopService = {
    list,
    get,
    createOrder,
    confirmOrder
}