import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { recaptchaRef } from '../../../ShopApp';
import { apiService } from '../../../_services/Api.Service';
import CustomCheck from './CustomCheck';

export default React.memo(({ setCc, setAgreePersonalInfo }) => {
  const [personalInfo, setPersonalInfo] = useState();
  const [validSsn, setValidSsn] = useState();
  const [ssn, setSsn] = useState();
  const [error, setError] = useState();
  const [concent, setConcent] = useState({ first: false, second: false });
  const ur = React.createRef();
  const [blacklisted, setBlacklisted] = useState();
  const [creditCheckStatus, setCreditCheckStatus] = useState();

  const { register, unregister, setValue } = useFormContext();

  useEffect(() => {
    register({ name: 'creditCheck' });
    return function cleanup() {
      unregister({ name: 'creditCheck' });
    };
  }, [register, unregister]);

  function onChange(e) {
    if (ur.current.value.length === 12) {
      setValidSsn(true);
      setSsn(ur.current.value);
    } else {
      setValidSsn(false);
      setSsn(null);
    }
  }

  const ConcentCheckboxes = () => {
    async function callBackend() {
      if (!(concent.first && validSsn)) return;

      let resp = 'test';

      if (process.env.NODE_ENV !== 'development') {
        resp = await recaptchaRef.current.executeAsync();
      }

      var captchaResponse = await apiService.Post('/credit/captcha', {
        ssn: ssn,
        response: resp,
      });

      var { blacklisted, userInformation, creditCheckStatus } = captchaResponse;

      setCreditCheckStatus(creditCheckStatus);

      if (blacklisted) {
        setBlacklisted(true);
      } else {
        setBlacklisted(false);

        if (userInformation.town) {
          let pi = {
            creditCheck: captchaResponse,
            user: userInformation,
          };

          setPersonalInfo(pi);
          setValue('creditCheck', captchaResponse.creditCheck);

          setCc(pi);
          setError(false);
        } else {
          setError(true);
        }
      }
    }

    const chk1 = (
      <CustomCheck
        id="teleco-concent-1"
        onChange={(checked) => setConcent({ ...concent, first: checked })}
        defaultChecked={concent.first}
      >
        <span>
          Jag godkänner att Teleco Mobile AB gör en kreditupplysning och hämtar min folkbokföringsadress samt samtycker
          till att mina personuppgifter behandlas i enlighet med nu gällande och kommande{' '}
          <a href="https://www.teleco.se/avtalsvillkor/" target="_blank" rel="noopener noreferrer">
            integritetspolicy
          </a>
          .
        </span>
      </CustomCheck>
    );

    return (
      <>
        <div style={{ fontSize: '14px', marginBottom: 5 }}>{chk1}</div>
        <button
          className="fetchadress shopBtn block"
          disabled={!(concent.first && validSsn)}
          variant="outline-primary"
          size="sm"
          onClick={callBackend}
        >
          Hämta adress
        </button>
      </>
    );
  };

  if (blacklisted) {
    return <p>Ditt personnummer verkar vara spärrat, vänligen kontakta supporten om du önskar lägga en beställning</p>;
  }

  //   if (creditCheckStatus === 2) {
  //     return (
  //       <p>
  //         Någonting gick fel, vänligen kontakta supporten om du önskar lägga en
  //         beställning
  //       </p>
  //     );
  //   }

  if (personalInfo?.user && !error) {
    const chk2 = (
      <CustomCheck
        id="teleco-concent-2"
        onChange={(checked) => {
          setConcent({ ...concent, second: checked });
          setAgreePersonalInfo(checked);
        }}
        defaultChecked={concent.second}
      >
        <span>
          {/* Jag godkänner gällande <a href="https://www.teleco.se/avtalsvillkor/" target="_blank" rel="noopener noreferrer">allmänna villkor</a> och försäkrar att personuppgifterna ovan är korrekta och avser mig som beställer. Avtalstiden är 24 månader med 1 månads uppsägningstid. */}
          {/*
                        Jag försäkrar att uppgifterna ovan är korrekta, legitimation uppvisas hos postombud. <br />
                        Jag godkänner Teleco Mobile ABs allmänna villkor samt villkor för detta avtal.
                    */}
          Jag godkänner{' '}
          <a href="https://www.teleco.se/avtalsvillkor/" taget="_blank" rel="noopener noreferrer">
            Teleco Mobile ABs Allmänna villkor
          </a>{' '}
          samt villkor för detta avtal. Abonnemanget är bundet i 24 månader med 1 månads uppsägningstid.
        </span>
      </CustomCheck>
    );

    return (
      <>
        <div>
          {personalInfo.user.name}
          <br />
          {personalInfo.user.address}
          <br />
          {personalInfo.user.coAddress && (
            <>
              {personalInfo.user.coAddress}
              <br />
            </>
          )}
          {personalInfo.user.zip} {personalInfo.user.town} <br />
          Sverige
          <br />
        </div>
        {chk2}
      </>
    );
  }

  return (
    <div>
      <Form.Control
        isInvalid={!validSsn}
        isValid={validSsn}
        onChange={onChange}
        ref={ur}
        type="text"
        placeholder="Personnummer (ååååmmddxxxx)"
      />

      <ConcentCheckboxes />

      {error && <div>Vi kunde inte hitta en address på </div>}
    </div>
  );
});
