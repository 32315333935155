import React from 'react'
import MyHelmet from '../../../components/MyHelmet'
import { Switch, Route } from 'react-router-dom'

import OrderList from './OrderList'
import OrderDetails from './OrderDetails'

export default ({ match }) => {
  return (
    <>
      <MyHelmet title={"Ordrar"} />

      <Switch>
        <Route exact path={`${match.path}`}>
          <OrderList />
        </Route>

        <Route path={`${match.path}/:id`}>
          <OrderDetails />
        </Route>

        <Route render={() => <h1>Not found</h1>} />
      </Switch>
    </>
  )
}