import React from "react";

import "./TecoRadio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/free-regular-svg-icons";
import cx from "classnames";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const RadioGroup = ({ name, onChange, children, className }) => {
  return (
    <div onChange={onChange} className={cx("teco__radiogroup", className)}>
      {children.map((child, idx) => {
        return <Radio key={idx} name={name} {...child.props} />;
      })}
    </div>
  );
};

const Radio = React.forwardRef(
  ({ icon, name, label, value, checked, right, middle }, ref) => {
    const id = `radio_${name}_${value}`;
    const internalIcon = icon ? icon : checked ? faCheckCircle : faCircle;

    // const { register, unregister, setValue } = useFormContext();

    // useEffect(() => {
    //     register({ name: name });
    //     return function cleanup() {
    //         unregister({ name: name });
    //     }
    // }, [name, register, unregister]);

    // function registerThis(e) {
    //     let val = parseInt(e.target.value);
    //     setValue(name, val);
    // }

    return (
      <span className="teco__radio">
        <input
          type="radio"
          id={id}
          name={name}
          defaultChecked={checked}
          value={value}
          //   onClick={registerThis}
        />
        <label htmlFor={id}>
          {/* <span className="icon"><FontAwesomeIcon icon={internalIcon} /></span> */}
          <div></div>
          <span className="text">{label}</span>
          {middle && <span className="middle">{middle}</span>}
          <span className="right">{right}</span>
        </label>
      </span>
    );
  }
);

export { RadioGroup, Radio };
