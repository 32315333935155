import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default React.forwardRef((_, ref) => {
  return (
    <ReCAPTCHA
      sitekey="6Lc55sAZAAAAAEv68CZARYGEDF1tkytcwFDEVCsx"
      ref={ref}
      size="invisible"
    />
  )
})