import React, { useReducer, useState, useContext, useEffect } from 'react';
import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form';
import { Form, Button, Spinner, Alert, Card, Col, Table, InputGroup } from 'react-bootstrap';
import { creditCheckService } from '../_services/CreditCheck.Service';
import DatePicker from 'react-datepicker';
import { apiService } from '../_services/Api.Service';
import { useQuery } from 'react-query';
import CreditCheckForm from '../components/CreditCheckForm';
import { OkAlert, BlacklistAlert, ProvningAlert } from '../components/CAF/CAFAlerts';
import { Link } from 'react-router-dom';
import PdfDownloadLink from '../components/PdfDownloadLink';

function reducer(state, action) {
  switch (action.type) {
    case 'resetForm':
      return initialState;
    case 'lockSsn':
      return { ...state, ssnLocked: true };
    case 'setLoading':
      return { ...state, isLoading: action.value, createOrderErrors: null, creditCheckErrors: null };
    case 'ccResponse':
      return { ...state, creditCheck: action.result };
    case 'creditCheckErrors':
      return { ...initialState, creditCheckErrors: action.errors };
    case 'createOrderErrors':
      return { ...initialState, createOrderErrors: action.errors };
    case 'orderCreated':
      return { ...initialState, order: action.order };
    default:
      throw new Error(`${action.type} not existing`);
  }
}

const postModel = {
  Ssn: 'ssn',
  Comment: 'comment',
  ContactNumber: 'contactNumber',
  ContactEmail: 'contactEmail',
  SubscriptionId: 'subscriptionId',
  ICCSuffix: 'ICCSuffix',
  NPDate: 'NPDate',
  NPMSISDN: 'NPMSISDN',
  SignTown: 'SignTown',
  ABO: 'ABO',
  Hardware: 'Hardware',
};

const ApplicationFormContext = React.createContext();

export const ConnectForm = ({ children }) => {
  const methods = useFormContext();

  return children({
    ...methods,
  });
};

const ApplicationForm = ({ context }) => {
  const { state, dispatch } = useContext(ApplicationFormContext);
  const formMethods = useForm();
  const { register, handleSubmit, setValue, control, errors } = formMethods;
  const [npTransferDate, setNpTransferDate] = useState();

  useEffect(() => {
    setValue(postModel.SignTown, state.creditCheck.signTown);
  }, [setValue, state.creditCheck.signTown]);

  useEffect(() => {
    register(postModel.NPDate);
    setValue(postModel.NPDate, npTransferDate);
  }, [setValue, npTransferDate, register]);

  const onSubmit = async (data) => {
    var postData = {
      creditCheck: state.creditCheck.creditCheck,
      ...data,
    };
    var response = await apiService.Post('/orders', postData);
    if (response.succeeded) {
      await dispatch({ type: 'orderCreated', order: response });
    } else {
      await dispatch({ type: 'createOrderErrors', errors: response.errors });
    }
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
          <ContactRow />
          <div>
            <Form.Row>
              <Col>
                <Controller
                  as={SubscriptionSelectTable}
                  name={postModel.SubscriptionId}
                  control={control}
                  rules={{ required: true }}
                  onSelect={(id) => {
                    setValue(`${postModel.SubscriptionId}`, id);
                  }}
                  customInput={<Form.Control type="hidden" />}
                />
                {errors[`${postModel.SubscriptionId}`] && <div className="text-danger">Välj ett abonnemang</div>}
              </Col>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} sm={12} controlId="exampleForm.ControlSelect1">
                <Form.Label>Förhöjd avgift</Form.Label>
                <Form.Control as="select" name={postModel.ABO} ref={register} selected={0}>
                  <option>0</option>
                  <optgroup label="Rabatt">
                    <option>-25</option>
                    <option>-50</option>
                    <option>-75</option>
                  </optgroup>
                  <optgroup label="Förhöjd">
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                    <option>125</option>
                    <option>150</option>
                    <option>175</option>
                    <option>200</option>
                    <option>225</option>
                    <option>250</option>
                    <option>275</option>
                    <option>300</option>
                    <option>325</option>
                    <option>350</option>
                    <option>375</option>
                    <option>400</option>
                    <option>425</option>
                    <option>450</option>
                  </optgroup>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} sm={12}>
                <Form.Label>Produkter</Form.Label>
                <Form.Control
                  name={postModel.Hardware}
                  as="textarea"
                  placeholder="Exempel: 'En iPhone 10 lämnades ut' eller 'Den dag/månad/år skickar vi ut XXX'"
                  rows={3}
                  ref={register}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Row>
                    <Col md={3} sm={12}>
                      <Form.Label>Önskar NP datum</Form.Label>
                      <DatePicker
                        selected={npTransferDate}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        onChange={(date) => {
                          setNpTransferDate(date);
                        }}
                      />
                    </Col>

                    <Col md={6} sm={12}>
                      <Form.Label>SIM-nr (5 siffror - 00000 om Teleco levererar)</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="SIM-nr">89462 09800 60000</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          isInvalid={errors[postModel.ICCSuffix]}
                          type="text"
                          aria-describedby="SIM-nr"
                          name={postModel.ICCSuffix}
                          ref={register({ required: true, minLength: 5, maxLength: 5 })}
                        />
                      </InputGroup>
                    </Col>

                    <Col md={3} sm={12}>
                      <Form.Label>Nummer att portera</Form.Label>
                      <Form.Control name={postModel.NPMSISDN} ref={register} type="text" />
                    </Col>
                  </Form.Row>
                </Form.Group>
              </Col>
            </Form.Row>
          </div>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Kommentar</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name={postModel.Comment}
                placeholder="Jag kom överrens med kunden att ..."
                ref={register}
              ></Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Ort</Form.Label>
              <Form.Control type="text" name={postModel.SignTown} ref={register}></Form.Control>
            </Form.Group>
          </Form.Row>
          <Button variant="primary" type="submit">
            Skapa order
          </Button>{' '}
          <Button
            variant="danger"
            onClick={() => {
              dispatch({ type: 'resetForm' });
            }}
          >
            Avbryt
          </Button>
        </Form>
      </FormProvider>
    </>
  );
};

const initialState = {
  isLoading: false,
};

export default () => {
  const [creditCheckErrors, setCreditCheckErrors] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const doCheck = async (data) => {
    await dispatch({ type: 'setLoading', value: true });
    var result = await creditCheckService.CheckCredit(data.ssn);

    if (result.succeeded) {
      await dispatch({ type: 'lockSsn' });
      await dispatch({ type: 'ccResponse', result: result });
    } else {
      await dispatch({ type: 'setLoading', value: false });
      setCreditCheckErrors(true);
    }

    await dispatch({ type: 'setLoading', value: false });
  };

  return (
    <>
      <div>
        <Card>
          <Card.Header className="bg-primary text-white">Kreditupplysning</Card.Header>
          <Card.Body>
            <ApplicationFormContext.Provider value={{ state, dispatch }}>
              <CreditCheckForm onSubmit={(data) => doCheck(data)} context={ApplicationFormContext} />

              {state.creditCheck && (
                <>
                  <BlacklistAlert blacklisted={state.creditCheck.blacklisted} />
                  <OkAlert limit={state.creditCheck.limit} />
                  <ProvningAlert extendedLimit={state.creditCheck.extendedLimit} />

                  <ApplicationForm context={ApplicationFormContext} />
                </>
              )}
            </ApplicationFormContext.Provider>
          </Card.Body>
        </Card>
      </div>
      <div>
        {state.order && <OrderCreatedAlert orderId={state.order.orderId} />}
        {state.createOrderErrors && (
          <Alert variant="danger" className="mt-4" dismissible>
            <Alert.Heading>Någonting gick fel!</Alert.Heading>
            <p>Vänligen kontakta supporten och uppge följande:</p>
            {state.createOrderErrors.map((error, idx) => {
              return <div key={idx}>{error}</div>;
            })}
          </Alert>
        )}
        {creditCheckErrors && (
          <Alert variant="danger" className="mt-4">
            <Alert.Heading>Någonting gick fel!</Alert.Heading>
            <p>Vänligen kontakta supporten</p>
          </Alert>
        )}
      </div>
    </>
  );
};

const OrderCreatedAlert = ({ orderId }) => {
  const [loading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  return (
    <Alert variant="success" className="mt-4" dismissible>
      <Alert.Heading>Order skapad!</Alert.Heading>
      <div>
        <p>
          Id: <Link to={`/orders/${orderId}`}>{orderId}</Link>
        </p>
        <PdfDownloadLink url={`/orders/${orderId}/pdf`} name={`${orderId}.pdf`} />
      </div>
      <div className="mt-2">
        <Button
          size="sm"
          disabled={loading || isConfirmed}
          variant={isConfirmed ? 'success' : 'primary'}
          onClick={async () => {
            setIsLoading(true);
            await apiService.Put(`/orders/${orderId}/updatestate?newState=1`);
            setTimeout(() => {
              setIsConfirmed(true);
              setIsLoading(false);
            }, 2000);
          }}
        >
          {isConfirmed ? 'Bekräftad!' : 'Bekräfta order'}{' '}
          {loading && <Spinner animation="border" role="status" size="sm" className="ml-2" />}
        </Button>
      </div>
    </Alert>
  );
};

const SubscriptionSelectTable = ({ onSelect }) => {
  const [selected, setSelected] = useState(0);
  useContext(ApplicationFormContext);
  const { data: subscriptions } = useQuery(`subscriptions`, async () => await apiService.Get('/subscriptions'));

  return (
    <Table size="sm" hover responsive>
      <thead>
        <tr>
          <th>Abonnemang</th>
          <th>Datapaket</th>
          <th>Månadsavgift</th>
          <th>Trafik</th>
        </tr>
      </thead>
      <tbody>
        {subscriptions
          ? subscriptions.map((sub, idx) => (
              <tr
                key={idx}
                className={selected === sub.id ? 'bg-info text-light' : ''}
                onClick={() => {
                  setSelected(sub.id);
                  onSelect(sub.id);
                }}
                style={{ cursor: 'pointer' }}
              >
                <td>{sub.name}</td>
                <td>{sub.dataPackage} GB</td>
                <td>{sub.monthlyFee} kr</td>
                <td style={{ minWidth: 200 }}>{sub.traffic}</td>
              </tr>
            ))
          : null}
      </tbody>
    </Table>
  );
};

const ContactRow = () => {
  let [contactValue, setContactValue] = useState('');
  return (
    <ConnectForm>
      {({ register, errors, setValue }) => (
        <Form.Row>
          <Form.Group as={Col} sm={6}>
            <Form.Label>
              Kontaktnummer<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name={postModel.ContactNumber}
              autoComplete="off"
              isInvalid={errors[postModel.ContactNumber]}
              value={contactValue}
              onChange={(e) => {
                if (isNaN(Number(e.target.value)) === false) {
                  setContactValue(e.target.value);
                  setValue(postModel.ContactNumber, Number(e.target.value));
                }
              }}
              ref={register({ required: true })}
            />
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Mailadress</Form.Label>
            <Form.Control type="text" name={postModel.ContactEmail} autoComplete="off" ref={register} />
          </Form.Group>
        </Form.Row>
      )}
    </ConnectForm>
  );
};
