import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TelecoHeader, ToNumberString } from '../../../../components/TelecoShared';
import { Radio, RadioGroup } from '../../radio/TecoRadio';
import cx from 'classnames';
import { Form } from 'react-bootstrap';
import CreditCheck from './CreditCheck';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { shopService } from '../../../_services/Shop.Service';
import ShopContext from '../ShopContext';

const SubScriptions = React.memo(({ setSub, selectedSub, subscriptions }) => {
  function onChange(event) {
    let subId = Number(event.target.value);
    let thisSub = subscriptions.find((e) => e.id === subId);
    setSub(thisSub);
  }

  return (
    <div className="subscriptionChoice">
      <TelecoHeader text="Välj abonnemang" />
      <RadioGroup name={'subscriptionId'} onChange={onChange} className={cx({ chosen: selectedSub })}>
        {subscriptions.map((sub, idx) => {
          const subText = `${sub.name}, ${sub.traffic}`;

          const monthlyFeeSpan = (
            <>
              {ToNumberString(sub.monthlyFee)} kr/mån
              {sub.monthlyListFee ? (
                <span className="originalPriceSpan">{ToNumberString(sub.monthlyListFee)} kr/mån</span>
              ) : (
                <></>
              )}
            </>
          );

          return (
            <Radio
              key={idx}
              label={subText}
              value={sub.id}
              right={monthlyFeeSpan}
              checked={sub.id === selectedSub?.id}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
});

const ContactInformation = ({ showKeepNPMSISDN, children }) => {
  const { register, getValues } = useFormContext();
  const [ciValid, setCiValid] = useState();
  const [formValid, setFormValid] = useState();

  function validateEmail(contactEmail) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(contactEmail).toLowerCase());
  }

  function validateNumber(contactNumber) {
    const re = /^[0-9]{6,}$/;
    return re.test(Number(contactNumber));
  }

  const onChange = useCallback(() => {
    var values = getValues();

    let emailValid = validateEmail(values.contactEmail);
    let numberValid = validateNumber(values.contactNumber);

    setFormValid({
      validated: true,
      email: { isValid: emailValid, isInvalid: !emailValid },
      number: { isValid: numberValid, isInvalid: !numberValid },
    });
  }, [getValues]);

  useEffect(() => {
    onChange();
  }, [onChange]);

  return (
    <>
      <div onChange={onChange}>
        <Form.Control
          type="email"
          name="contactEmail"
          {...formValid?.email}
          ref={register({ required: true })}
          placeholder="E-postadress"
          style={{ marginBottom: '10px' }}
        />
        <Form.Control
          type="tel"
          name="contactNumber"
          {...formValid?.number}
          ref={register({ required: true })}
          placeholder="Telefonnummer (07xxxxxxxx)"
          style={{ marginBottom: '10px' }}
        />
        {showKeepNPMSISDN && (
          <Form.Control
            type="tel"
            name="NPMSISDN"
            ref={register}
            placeholder="Telefonnummer att behålla (frivilligt)"
            style={{ marginBottom: '10px' }}
          />
        )}
        <div>{ciValid && children}</div>
      </div>
    </>
  );
};

const OrderConfirmation = ({ selectedSub, product, readyToOrder }) => {
  const monthlyFee = selectedSub.monthlyFee + product.abo;
  const totalPrice = monthlyFee * 24;

  return (
    <>
      <div style={{ marginTop: '10px' }}>
        <button className="shopBtn block" type="submit" disabled={!readyToOrder}>
          Beställ abonnemang {ToNumberString(monthlyFee)} kr/mån
        </button>
        <div>
          <div className="text-center" style={{ fontSize: '14px', fontStyle: 'italic' }}>
            Månadskostnad {ToNumberString(monthlyFee)} kr i 24 mån, minsta totalkostnad {ToNumberString(totalPrice)} kr.
          </div>
        </div>
      </div>
    </>
  );
};

const SubscriptionPurchaseForm = ({ product }) => {
  // return <ProductPurchaseFormModule product={product} />;
  const [selectedSub, setSub] = useState();
  const [personalInfo, setPersonalInfo] = useState();
  const [agreePersonalInfo, setAgreePersonalInfo] = useState();

  const [shopState, setShopState] = useContext(ShopContext);

  const formMethods = useForm({
    mode: 'onChange',
  });

  async function onSubmitPurchase(data, e) {
    const form = e.target;

    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity()) {
      if (data.creditCheck == null) return;

      let orderData = {
        ...data,
        productId: product.id,
        subscriptionId: selectedSub.id,
      };
      let createOrderResponse = await shopService.createOrder(orderData);

      if (createOrderResponse.error) {
        let message = '';
        switch (createOrderResponse.error) {
          case 'ERROR-10021':
          default:
            message =
              'Beställningen kunde inte genomföras, vänligen kontakta support, fel ' + createOrderResponse.error;
        }

        setShopState({
          ...shopState,
          completed: true,
          data: null,
          error: message,
        });
      } else {
        // setCompletedOrderId(createOrderResponse.orderId);
        setShopState({
          ...shopState,
          completed: true,
          data: { orderId: createOrderResponse.orderId, ptype: 'subscription' },
          error: null,
        });
      }
    }
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmitPurchase)} className="productform">
          <SubScriptions setSub={setSub} selectedSub={selectedSub} subscriptions={product.allowedSubscriptions} />
          {!selectedSub && (
            <>
              <div>
                <strong>Teleco Mobil - Enkelt att välja rätt</strong>
                <p>
                  Med våra mobilabonnemang ringer du fritt inom Sverige, surfar fritt inom EU/EES och smsar gratis till
                  hela världen. Dessutom har du möjlighet att testa täckningen hemma i lugn och ro, vi erbjuder alltid
                  14 dagars ångerrätt. Villkor för övriga tjänster, så som mobilt bredband och hemtelefon, se{' '}
                  <a href="https://www.teleco.se/avtalsvillkor" target="_blank" rel="noopener noreferrer">
                    www.teleco.se/avtalsvillkor
                  </a>
                  .
                </p>
              </div>
            </>
          )}
          {selectedSub && (
            <>
              <div className="customerInformation">
                <TelecoHeader text="Kunduppgifter" />
                <ContactInformation showKeepNPMSISDN={true} />
                <CreditCheck setCc={setPersonalInfo} setAgreePersonalInfo={setAgreePersonalInfo} />
              </div>

              <OrderConfirmation
                selectedSub={selectedSub}
                product={product}
                readyToOrder={personalInfo && agreePersonalInfo}
              />
            </>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default SubscriptionPurchaseForm;
