import { BehaviorSubject } from "rxjs";

import { AUTH_TOKEN, AUTH_STATUS } from "../api/endpoints";

const storageCurrentUserString = "currentUser";

// var storageProvider = localStorage;

const initilUser = { user: null, token: null }

const currentUserSubject = new BehaviorSubject(
  JSON.parse(getCookie(storageCurrentUserString) || JSON.stringify(initilUser))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get isAdmin() {
    return currentUserSubject.value.roles.includes("Admin");
  },
  get currentUserValue() {
    return currentUserSubject.value;
  },
  get currentUserGivenName() {
    let user = currentUserSubject.value;
    return user.fullName
  },
  get isLoggedIn() {
    let loggedInResult = false;
    const hasUser = currentUserSubject.value !== null && currentUserSubject.value.token !== null;

    loggedInResult = hasUser && this.tokenExpired === false

    return loggedInResult
  },
  get tokenExpired() {
    const lastLogin = new Date(currentUserSubject.value.lastLoginDate);
    const expiryDate = new Date(lastLogin.getTime() + currentUserSubject.value.token.expires_in * 1000);

    const expired = Date.now() > expiryDate;

    return expired;
  }
};

async function login({ username, password }) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  try {
    var url = `${AUTH_TOKEN}`;
    var resp = await fetch(url, requestOptions);
    var token = await resp.json();

    var access_token = token.access_token;
    let defaultOptions = {
      headers: {
        'Authorization': 'Bearer ' + access_token,
      }
    }

    var respStatus = await fetch(`${AUTH_STATUS}`, defaultOptions);

    if (respStatus.status === 401)
      return false;

    var userStatus = await respStatus.json();

    var obj = { ...userStatus, token: token };
    setCookie(storageCurrentUserString, JSON.stringify(obj), 1)
    // localStorage.setItem(storageCurrentUserString, JSON.stringify(obj));
    currentUserSubject.next(obj);

    return true;
  }
  catch (error) {
    return false;
  }
}

async function logout() {
  if (this.isLoggedIn) {
    eraseCookie(storageCurrentUserString);
    // localStorage.removeItem(storageCurrentUserString);
    currentUserSubject.next(null);
    window.location = "/";
  }
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}