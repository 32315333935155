import { baseUrl } from "./ApiEndpoints.js";

const Get = async (url) => {
  return internalApiFetch(url, {});
};

const Post = async (url, body, headers) => {
  return internalApiFetch(
    url,
    { method: "POST", body: JSON.stringify(body) },
    headers
  );
};

const internalApiFetch = async (url, options, headers) => {
  let defaultOptions = {
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  };

  var pathedOptions = { ...defaultOptions, ...options };

  try {
    //relative url prepend base
    if (url[0] === "/") url = `${baseUrl}${url}`;

    var response = await fetch(url, pathedOptions);

    switch (response.status) {
      case 201:
        return response.headers.get("Location");
      case 204:
        return true;
      default:
        try {
          var json = await response.json();
          return json;
        } catch (error) {
          throw new Error(`whoops! Status ${response.status} - ${error}`);
        }
    }
  } catch (error) {
    throw error;
  }
};

export const apiService = {
  Get,
  Post,
};
