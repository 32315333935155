import React, { useCallback, useState, useEffect, useContext } from 'react';
import MyHelmet from '../../components/MyHelmet';
import { Button, Spinner, Table, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useDropzone } from 'react-dropzone';
import { apiService } from '../../_services/Api.Service';
import PdfDownloadLink from '../../components/PdfDownloadLink';
import { faArrowUp, faArrowDown, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

async function uploadFile(files, setter) {
  setter(true);
  let formData = new FormData();
  files.forEach((file, idx) => {
    formData.append(`Files`, file);
  });
  await apiService.Upload('/pdf', formData);
  setter(false);
}

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function AgreementDropZone() {
  const { state, setState } = useContext(PdfContext);

  // const [file, setFile] = useState();
  const [files, setFiles] = useState();
  const [dropped, setDropped] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setDropped(true);
    setFiles(acceptedFiles);
    // acceptedFiles.forEach((file) => {
    //   // setPdf(window.URL.createObjectURL(file));
    //   setFile(file);
    // })
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'application/pdf' });

  return (
    <>
      <div accept="application/pdf" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>
            <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faUpload} /> Släpp filen här
          </p>
        ) : (
          <p>
            <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faUpload} /> Dra filer hit, eller klicka här för att
            ladda upp
          </p>
        )}
      </div>
      {files && (
        <div>
          {files.map((file, idx) => {
            return <div key={idx}>{file.path}</div>;
          })}
        </div>
      )}
      {dropped && (
        <Button
          size="sm"
          onClick={async () => {
            await uploadFile(files, setIsUploading);
            setFiles(null);
            setDropped(false);
            setState({ ...state, refreshToken: Math.random() });
          }}
        >
          Ladda upp {isUploading && <Spinner animation="border" role="status" size="sm" />}
        </Button>
      )}
    </>
  );
}

const PdfContext = React.createContext([{}, () => {}]);

const PdfModule = () => {
  const [state, setState] = useState({});

  // fetch agreement
  useEffect(() => {
    async function getPdf() {
      var pdfs = await apiService.Get('/pdf');

      let parsedPdfs = [];
      pdfs.forEach((pdf) => {
        var bytes = _base64ToArrayBuffer(pdfs[0].bytes);
        var file = new File([bytes], `test.pdf`, { type: 'application/pdf' });
        let objUrl = window.URL.createObjectURL(file);
        parsedPdfs.push({ id: pdf.id, url: objUrl, sort: pdf.sort });
      });

      setState({ ...state, parsedPdfs: parsedPdfs, pdfs: pdfs });
    }
    getPdf();
  }, [state.refreshToken]);

  const removeTooltip = (props) => <Tooltip {...props}>Ta bort</Tooltip>;
  const upTooltip = (props) => <Tooltip {...props}>Flytta upp</Tooltip>;
  const downTooltip = (props) => <Tooltip {...props}>Flytta ner</Tooltip>;

  async function sort(pdf, direction) {
    var isOk = true;
    if (pdf.sort === 0 || (pdf.sort === 1 && direction === -1))
      isOk = window.confirm('Är du säker på att du vill byta ut avtalssidan?');
    if (isOk) {
      await apiService.Put(`/pdf/${pdf.id}/sort?direction=${direction}`);
      refreshList();
    }
  }

  async function remove(pdf) {
    if (window.confirm(`Är du säker på att du vill ta bort '${pdf.fileName}'?`)) {
      await apiService.Delete(`/pdf/${pdf.id}`);
      refreshList();
    }
  }

  function refreshList() {
    setState({ ...state, refreshToken: Math.random() });
  }

  return (
    <PdfContext.Provider value={{ state, setState }}>
      <MyHelmet title={'PDF Admin'} />

      <h5>Avtalssidor</h5>

      {state.pdfs && (
        <>
          <Form className="mb-4">
            <AgreementDropZone />
            <Form.Text>Filen högst upp är avtalet, flytta inte ner den.</Form.Text>
          </Form>

          <Table size="sm" responsive hover>
            <thead>
              <tr>
                <th>Namn</th>
                <th>Typ</th>
                <th>Storlek</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.pdfs.map((pdf, idx) => {
                function humanFileSize(size) {
                  var i = Math.floor(Math.log(size) / Math.log(1024));
                  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
                }

                const canSortUp = pdf.sort !== 0;
                const canSortDown = pdf.sort !== state.pdfs.length - 1;

                const sortUpStuff = {
                  style: {
                    cursor: 'pointer',
                  },
                  className: canSortUp ? '' : 'fa-disabled',
                  icon: faArrowUp,
                  onClick: canSortUp ? () => sort(pdf, -1) : null,
                };
                const sortDownStuff = {
                  style: {
                    cursor: 'pointer',
                  },
                  className: canSortDown ? '' : 'fa-disabled',
                  icon: faArrowDown,
                  onClick: canSortDown ? () => sort(pdf, 1) : null,
                };

                const SortUp = () => (
                  <OverlayTrigger delay={{ show: 200, hide: 0 }} overlay={upTooltip}>
                    <FontAwesomeIcon {...sortUpStuff} />
                  </OverlayTrigger>
                );

                const SortDown = () => (
                  <OverlayTrigger delay={{ show: 200, hide: 0 }} overlay={downTooltip}>
                    <FontAwesomeIcon {...sortDownStuff} />
                  </OverlayTrigger>
                );

                const Remove = () => (
                  <OverlayTrigger delay={{ show: 200, hide: 0 }} overlay={removeTooltip}>
                    <FontAwesomeIcon
                      style={{ cursor: 'pointer', color: 'red' }}
                      icon={faTrashAlt}
                      onClick={() => remove(pdf)}
                    />
                  </OverlayTrigger>
                );

                return (
                  <tr key={idx}>
                    <td>{pdf.fileName}</td>
                    <td>{pdf.contentType}</td>
                    <td>{humanFileSize(pdf.size)}</td>
                    <td>
                      <PdfDownloadLink url={`/pdf/${pdf.id}`} name={`${pdf.fileName}`} /> <SortUp /> <SortDown />
                    </td>
                    <td>
                      <Remove />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
      {!state.pdfs && <div>Laddar...</div>}
    </PdfContext.Provider>
  );
};

export default PdfModule;
