import React from 'react'
import Blacklist from '../../components/Blacklist'
import MyHelmet from '../../components/MyHelmet'

export default () => {

  return (
    <>
      <MyHelmet title="Svartlista" />
      <h1>Spärrlista</h1>
      <Blacklist />
    </>
  )
}