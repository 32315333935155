import React from 'react'
import { Alert } from "react-bootstrap"


function toNumberString(input){
  return parseFloat(input).toLocaleString("se").replaceAll(",", " ")
}

const NumberFormatter = ({ number }) => {
  function toSwedish(x) {
    return x.toLocaleString('sv-SE');
  }

  return <>{toSwedish(number)}</>
}

const OkAlert = ({ limit }) =>
  <Alert variant="success">
    Kund är godkänd för en total månadskostnad upp till <strong className="text-nowrap"><NumberFormatter number={limit} /> kr.</strong>
  </Alert>

const BlacklistAlert = ({ blacklisted }) => {
  return (
    <>
      {blacklisted && (
        <Alert variant="danger">
          <strong>Varning!</strong> Denna kund är med på vår spärrlista. Det betyder att kund eventuellt
      redan har flera abonnemang hos oss eller så kan de på eget bevåg begärt att få sitt personnummer
      spärrat. Teleco kommer göra en egen kontroll av beställningen innan den godkänns. Notera att
      Teleco inte tar ansvar för hårdvaror utlämnade till kunden innan vi godkänt ordern. Behöver
      ni ett besked med det samma av denna anledning kan ni helgfria vardagar 9-16 kontakta oss
      på <a href="tel:+46771404540">0771-40 45 40</a>.
        </Alert>
      )}
    </>
  )
}

const ProvningAlert = ({ extendedLimit }) =>
  <Alert variant="warning">
    PRÖVNING: Önskar ni en vidare prövning av kunden så kan ni lägga in ett avtal med en total månadskostnad upp till <strong className="text-nowrap"><NumberFormatter number={toNumberString(extendedLimit)} /> kr</strong>.
    <div>
      Teleco genomför då en ytterligare kreditupplysning och meddelar er och kund slutgiltigt besked via mail/sms. 
      Notera att Teleco inte tar ansvar för hårdvaror utlämnade till kunder vars kreditvärdighet ska prövas. 
      Lämna heller aldrig ut SIM-kort till en kund där ni vill ha en manuell prövning av kreditvärdigheten, vi skickar ut SIM till kunden om de blir godkända.
    </div>
</Alert>

export {
  OkAlert,
  BlacklistAlert,
  ProvningAlert
}