import React from 'react'
import { Switch, Route, Link } from "react-router-dom"
import SubscriptionTable from './SubscriptionList'
import SubscriptionDetails from './SubscriptionDetails'

import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import MyHelmet from '../../../components/MyHelmet'

export default ({ match }) => {
  let history = useHistory();
  return (
    <>
      <h1>Abonnemang</h1>
      <div>
        <MyHelmet title={"Abonnemang"} />
      </div>
      <div>
        <Switch>
          <Route exact path={`${match.path}`}>
            <div className="mb-2">
              <Link to="/subscriptions/create" className="mb-4">
                <Button variant="success" size="sm">
                  <FontAwesomeIcon icon={faPlus} />{' '}Skapa
                </Button>
              </Link>
            </div>
            <SubscriptionTable onRowSelected={(id) => history.push(`${match.path}/${id}`)} />
          </Route>

          <Route path={[`${match.path}/:id`, `${match.path}/create`]}>
            <SubscriptionDetails />
          </Route>

          <Route render={() => <h1>Not found</h1>} />
        </Switch>
      </div>
    </>
  )
}
