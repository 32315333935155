import React from "react";
import { Link } from "react-router-dom";

export default ({ children, ...props }) => {
  return (
    <div>
      <h1>404!</h1>
      <p>Sidan du försökte nå kunde inte hittas.</p>

      <div>
        <Link to="/" >Gå hem...</Link>
      </div>
      <div>
        <Link to="/login" >Logga in på nytt</Link>
      </div>
    </div>
  );
};
