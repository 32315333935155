import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import "./AuthContainer.css";

import { Row, Col, Container, Form, Button, Alert, Spinner } from "react-bootstrap";
import { authenticationService } from "../_services/Authentication.Service";
import { formDefaults } from "../_services/DefaultValues.Service";

export default ({ history, location }) => {
  const { register, handleSubmit, errors } = useForm({defaultValues: formDefaults.auth});
  const [loginError, setLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function postLoginDetails(formdata) {
    setIsLoading(true);
    var result = await authenticationService.login(formdata);
    if (result)
      return history.replace(location?.state?.referrer ?? "/");
    else
      setLoginError(true);
    setIsLoading(false);
  }

  useEffect(() => {
    authenticationService.logout();
  });


  return (
    <Container>

      <Form className="LoginForm text-white mt-4" onSubmit={handleSubmit(postLoginDetails)}>
        <h2 className="text-center mb-4">Välkommen till Orderportalen!</h2>

        {loginError &&
          <div className="text-center text-danger">
            <h5 className="text-center text-danger">Det gick inte att logga in.</h5>
            <p>Vänligen kontakta Teleco om du anser att något är fel.</p>
          </div>
        }

        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Mailadress
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              name="username"
              size="sm"
              type="email"
              placeholder="email@example.com"
              autoFocus
              ref={register({ required: true })}
            />
          </Col>
          {errors.username && <span>This field is required</span>}
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Lösenord
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              name="password"
              size="sm"
              type="password"
              placeholder="*********"
              ref={register({ required: true })} />
          </Col>
          {errors.password && <span>This field is required</span>}
        </Form.Group>

        <Button block type="submit" disabled={isLoading}>
          Logga in
          {isLoading && <Spinner animation="border" role="status" size="sm" className="ml-2" />}
        </Button>
      </Form>

      {location.state?.tokenExpired &&
        <Alert variant="info" className="mt-4" style={{ maxWidth: 600, margin: "0 auto" }}>
          <Alert.Heading>
            Din session har gått ut
          </Alert.Heading>
          <p>Vänligen logga in på nytt.</p>
        </Alert>
      }
    </Container >
  );
};
