import React, { useState } from 'react'
import { apiService } from '../_services/Api.Service';
import { Button, Spinner } from 'react-bootstrap';
import ConfirmButton from './ConfirmButton';
import { baseUrl } from '../api/endpoints';


const LoadingButton = ({ onClick, children, ...rest }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button {...rest} className={loading ? 'disabled' : ''} onClick={async () => {
      setLoading(true);
      await onClick();
      setLoading(false);
    }}>
      {children}
      {loading &&
        <Spinner animation="border" role="status" size="sm" className="ml-2">
          <span className="sr-only">Loading...</span>
        </Spinner>
      }

    </Button>
  )
}

export default ({ selectedOrders, setSelectedOrders, setRefreshToken }) => {
  async function getPendingExports() {
    let data = await apiService.GetFile("/orders/export");
    await downloadFile(data);
  }

  async function getSelected() {
    let data = await apiService.GetFile("/orders/export", { method: "POST", body: JSON.stringify({ orders: selectedOrders }) });
    await downloadFile(data);
  }

  async function setSelectedAsDone() {
    let result = await apiService.Post("/orders/completemultipleorders", { orders: selectedOrders });
    if (result) {
      setSelectedOrders([]);
      setRefreshToken(Math.random);
    }
  }

  async function downloadFile(data) {
    var url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `Export.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  return (
    <>
      <form method="get" action={`${baseUrl}/orders/export`} style={{ display: "inline-block" }}>
        {selectedOrders?.map((o, idx) => <input key={idx} type="hidden" name="Orders" value={o} />)}
        <Button size="sm" type="submit">Exportera bekräftade</Button>
      </form>{' '}

      <form method="post" action={`${baseUrl}/orders/export`} style={{ display: "inline-block" }}>
        {selectedOrders?.map((o, idx) => <input key={idx} type="hidden" name="Orders" value={o} />)}
        <Button size="sm" type="submit" disabled={selectedOrders.length === 0} variant="info">Exportera valda</Button>
      </form>{' '}
      
      <ConfirmButton size="sm" disabled={selectedOrders.length === 0} onClick={setSelectedAsDone} variant="warning" text="Klarmarkera valda" deps={selectedOrders} />{' '}
      {selectedOrders.length !== 0 && <Button size="sm" variant="link" onClick={() => setSelectedOrders([])}>Avmarkera alla {selectedOrders.length}</Button>}
    </>
  )
}
