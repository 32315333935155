import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';

const DangerButton = ({ children, ...rest }) => <Button variant="danger" {...rest}>{children}</Button>

export default ({ onClick, text, confirmText, toggle, deps, ...rest }) => {
  const [asked, setAsked] = useState(false);

  useEffect(() => {
    setAsked(false);
  }, [deps]);

  text = text || "Ta bort";
  confirmText = confirmText || "Är du säker?";

  async function handleClick() {
    if (asked)
      await onClick();

    setAsked(!asked);
  }

  return (
    <DangerButton {...rest} onClick={handleClick}>{asked ? confirmText : text}</DangerButton>
  )
}