import React, { useState, useContext } from 'react'
import { Form, Row, Col, Table, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form'
import { apiService } from '../_services/Api.Service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ShortDateTime from './ShortDateTime';
import PagedQuery from './PagedQuery';


const RefreshTokenContext = React.createContext();

const AddSsnForm = ({ setRefreshToken }) => {

  const [validated, setValidated] = useState(false);
  const [blackListErrors, setBlackListErrors] = useState([]);

  async function handleSubmitValidation (data) {
    setValidated(true);
    var result = await apiService.Post("/blacklist", data);
    if (result.errors) {
      setBlackListErrors(result.errors);
    }
    rt.triggerRefresh();
  };

  const { register, handleSubmit } = useForm();
  let rt = useContext(RefreshTokenContext);

  return (
    <Form noValidate validated={validated} className="mb-4" onSubmit={handleSubmit(handleSubmitValidation)}>
      <Row>
        <Col>
          <Form.Label>
            Personnummer & kommentar
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col sm="10">
          <Form.Control
            autoComplete="off"
            as="textarea"
            rows={3}
            name="ssn"
            size="sm"
            placeholder="varje rad är en ny person, alla tecken efter yyyymmddxxxx är kommentar
yyyymmddxxxx denna person är med på spärrlistan pga. xxxxx
yyyymmddxxxx med anledning av yyyy"
            ref={register({ required: true })}></Form.Control>
        </Col>
        <Col sm="2">
          <Button size="sm" variant="success" type="submit" block>
            <FontAwesomeIcon icon={faPlus} /> {' '}
            Lägg till
          </Button>
        </Col>
      </Row>
      {blackListErrors.map((error, idx) => {
        return <Row key={idx}><Col>{error}</Col></Row>
      })}
    </Form>
  )
}

const BlacklistTable = () => {
  let rt = useContext(RefreshTokenContext);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  // const { status, data = [], error } = useQuery(['blacklist', rt.refreshToken], async () => { return apiService.Get("/blacklist") });

  var debounceTimer;
  function doSearch(search) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setSearch(search)
    }, 330)
  }

  return (
    <>
      {/* {error && (
        <Alert variant="danger">{error.message}</Alert>
      )} */}

      <Form.Group>
        <Form.Label>Sök</Form.Label>
        <Form.Control onChange={({ target }) => doSearch(target.value)} />
      </Form.Group>

      <PagedQuery url="/blacklist" setData={setData} search={search} refreshToken={rt.refreshToken}>
        <Table hover size="sm">
          <thead>
            <tr>
              <th>Personnummer</th>
              <th>Spärrad datum</th>
              <th>Spärrad av</th>
              <th>Kommentar</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((bl, idx) => {
              return (
                <tr key={idx}>
                  <td>{bl.ssn}</td>
                  <td><ShortDateTime date={bl.blacklistedDate} /></td>
                  <td><Link to={`/users/${bl.blacklistedByUser.id}`}>{bl.blacklistedByUser?.userName}</Link></td>
                  <td>{bl.comment}</td>
                  <td>
                    <Button className="float-right" size="sm" variant="danger" onClick={async () => {
                      await apiService.Delete(`/blacklist/${bl.ssn}`);
                      rt.triggerRefresh();
                    }}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </PagedQuery>
    </>
  )
}

export default () => {
  const [refreshToken, setRefreshToken] = useState(1);
  return (
    <>
      <RefreshTokenContext.Provider value={{ refreshToken, triggerRefresh: () => { setRefreshToken(Math.random()); } }}>
        <AddSsnForm />
        <BlacklistTable />
      </RefreshTokenContext.Provider>
    </>
  )
}