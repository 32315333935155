import React from "react";
import { Router } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { createBrowserHistory } from "history";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-datepicker/dist/react-datepicker.css';
import "./App.css";

import { HelmetProvider } from "react-helmet-async";
import MyHelmet from "./components/MyHelmet";

const helmetContext = {};

function App() {
  return (
      <HelmetProvider context={helmetContext}>
        <MyHelmet />
        <Router history={createBrowserHistory()}>
          <AppRoutes />
        </Router>
      </HelmetProvider>
  );
}

export default App;
