import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import "./StripeCardPayment.scss";
import { apiService } from "../../../_services/Api.Service";
import { Spinner } from "react-bootstrap";

export default function StripeCardPayment({
  product,
  intent,
  onSucceeded,
  onError,
  onProcessing,
}) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [orderId, setOrderId] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    async function createPaymentIntent() {
      var postData = {
        productId: product.id,
        ...intent,
      };

      var resp = await apiService.Post("/store/intent", postData);
      setClientSecret(resp.clientSecret);
      setOrderId(resp.orderId);
    }

    createPaymentIntent();
  }, [product.id, intent]);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    if (typeof onProcessing === "function") onProcessing();
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value,
        },
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      if (typeof onError === "function") onError();
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);

      var confirmOrderResult = await apiService.Post(
        `/store/intent/${orderId}`,
        { orderId: orderId }
      );
      if (typeof onSucceeded === "function")
        onSucceeded({
          orderId: orderId,
          receiptUrl: confirmOrderResult.receiptUrls,
        });
    }
  };

  if (!clientSecret) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", paddingBottom: 10 }}
      >
        <Spinner animation="border" role="status" size="lg">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />
      <button disabled={processing || disabled || succeeded} id="submit">
        <span id="button-text">
          {processing ? <div className="spinner" id="spinner"></div> : "Betala"}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a href={`${process.env.STRIPE_URL}`}>
          {" "}
          Stripe dashboard.
        </a>{" "}
        Refresh the page to pay again.
      </p> */}
    </form>
  );
}
