import { authenticationService } from "./Authentication.Service";
import { baseUrl } from '../api/endpoints'

const Get = async (url) => {
  return internalApiFetch(url, {});
}

const GetFile = async (url, options) => {
  return internalApiFetchFile(url, options ?? {});
}

const Post = async (url, body, headers) => {
  return internalApiFetch(url, { method: "POST", body: JSON.stringify(body) }, headers);
}

const Upload = async (url, body) => {
  var currentUserToken = authenticationService.currentUserValue.token;

  let options = {
    headers: {
      "Authorization": "Bearer " + currentUserToken.access_token
    },
    method: "POST",
    body: body
  }

  await fetch(`${baseUrl}${url}`, options);
}

const Put = async (url, body) => {
  return internalApiFetch(url, { method: "PUT", body: JSON.stringify(body) });
}

const Patch = async (url, body) => {
  return internalApiFetch(url, { method: "PATCH", body: JSON.stringify(body) });
}

const Delete = async (url) => {
  return internalApiFetch(url, { method: "DELETE" });
}

const internalApiFetchFile = async (url, options) => {
  var currentUserToken = authenticationService.currentUserValue?.token;

  let defaultOptions = {
    headers: {
      "Content-Type": "application/json"
    }
  }

  if (currentUserToken) defaultOptions.headers = {
    ...defaultOptions.headers,
    "Authorization": "Bearer " + currentUserToken?.access_token
  }

  var pathedOptions = { ...defaultOptions, ...options };

  var response = await fetch(`${baseUrl}${url}`, pathedOptions);
  var data = null;
  var okresult = response.status === 200;
  if (okresult)
    data = await response.blob();
  return {
    error: !okresult,
    data: data
  }
}

const internalApiFetch = async (url, options, headers) => {
  var currentUserToken = authenticationService.currentUserValue?.token;

  let defaultOptions = {
    headers: {
      "Content-Type": "application/json",
      ...headers
    }
  }

  if (currentUserToken) defaultOptions.headers = {
    ...defaultOptions.headers,
    "Authorization": "Bearer " + currentUserToken?.access_token,
    "SecurityStamp": currentUserToken.securityStamp,
  }

  var pathedOptions = { ...defaultOptions, ...options };

  try {
    var response = await fetch(`${baseUrl}${url}`, pathedOptions);

    switch (response.status) {
      case 201:
        return response.headers.get("Location");
      case 204:
        return true;
      case 401:
        return authenticationService.logout();
      default:
        try {
          var json = await response.json();
          return json;
        }
        catch (error) {
          throw new Error(`whoops! Status ${response.status} - ${error}`);
        }
    }

  }
  catch (error) {
    throw error;
  }
}

export const apiService = {
  Get,
  GetFile,
  Post,
  Put,
  Patch,
  Delete,
  Upload
}