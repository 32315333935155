import React, { useState } from "react";
import { Table, Form } from "react-bootstrap";
import ShortDateTime from "../../../components/ShortDateTime";
import PagedQuery from "../../../components/PagedQuery";

export default ({ onRowSelected }) => {
  const [users, setUsers] = useState([]);

  const [search, setSearch] = useState("");
  var debounceTimer;
  function doSearch(search) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setSearch(search)
    }, 330)
  }

  return (
    <div>

      <Form.Group>
        <Form.Label>Sök</Form.Label>
        <Form.Control onChange={({ target }) => doSearch(target.value)} />
      </Form.Group>

      <PagedQuery url="/users" setData={setUsers} search={search}>

        <Table hover size="sm">
          <thead>
            <tr>
              <th>Återförsäljare</th>
              <th>Namn</th>
              <th>Email</th>
              <th>Skapad</th>
              <th>Senast inloggningen</th>
            </tr>
          </thead>
          <tbody>
            {users && users.map((user, index) => (
              <tr key={index} onClick={() => onRowSelected(user.id)}>
                <td>{user.reseller}</td>
                <td>{user.fullName}</td>
                <td>{user.userName}</td>
                <td><ShortDateTime date={user.createdDate} /></td>
                <td><ShortDateTime time date={user.lastLoginDate} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </PagedQuery>
    </div>
  );
};
