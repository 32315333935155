import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { TelecoHeader } from '../../../../components/TelecoShared';

const DirectPurchaseContactInformation = ({ setContactInfo, children }) => {
  const { register, getValues, formState } = useFormContext();
  const { isValid: submitFormIsValid, touched = {}, dirtyFields } = formState;
  const [formValid, setFormValid] = useState({});

  function validateEmail(contactEmail) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(contactEmail).toLowerCase());
  }

  function validateNumber(contactNumber) {
    const re = /^[0-9]{6,}$/;
    return re.test(Number(contactNumber));
  }

  const onChange = useCallback(() => {
    let ftf = { ...dirtyFields, ...touched };
    var values = getValues();

    const ssnValid = values.ssn.length === 12;
    const emailValid = validateEmail(values.email);
    const contactNumberValid = validateNumber(values.contactNumber);
    const nameValid = values.name?.length > 0;
    const addressValid = values.address?.address.length > 0;
    const zipValid = values.address?.zip.length > 0;
    const townValid = values.address?.town.length > 0;

    const formValidState = {
      ...formValid,
      ssn: { isValid: ssnValid, isInvalid: !ssnValid && ftf.ssn },
      name: { isValid: nameValid, isInvalid: !nameValid && ftf.name },
      email: { isValid: emailValid, isInvalid: !emailValid && ftf.email },
      contactNumber: { isValid: contactNumberValid, isInvalid: !contactNumberValid && ftf.contactNumber },
      address: {
        address: { isValid: addressValid, isInvalid: !addressValid && ftf.address?.address },
        zip: { isValid: zipValid, isInvalid: !zipValid && ftf.address?.zip },
        town: { isValid: townValid, isInvalid: !townValid && ftf.address?.town },
      },
    };
    setFormValid(formValidState);

    console.log(ftf);

    setContactInfo(submitFormIsValid ? values : null);
  }, [getValues, setContactInfo, submitFormIsValid]);

  return (
    <>
      <TelecoHeader text="Kunduppgifter" />
      <div onChange={onChange}>
        <Form.Control
          type="text"
          name="ssn"
          {...formValid.ssn}
          ref={register({ required: true })}
          placeholder="Personnummer (ååååmmddxxxx)"
          autoComplete="off"
        />

        <Form.Control
          type="text"
          name="name"
          {...formValid.name}
          ref={register({ required: true })}
          placeholder="Namn"
          autoComplete="name"
        />
        <Form.Control
          type="email"
          name="email"
          {...formValid.email}
          ref={register({ required: true })}
          placeholder="E-postadress"
          autoComplete="email"
        />
        <Form.Control
          type="tel"
          name="contactNumber"
          {...formValid.contactNumber}
          ref={register({ required: true })}
          placeholder="Telefonnummer (07xxxxxxxx)"
          autoComplete="tel"
        />

        <Form.Control
          type="text"
          name="address.address"
          {...formValid.address?.address}
          ref={register({ required: true })}
          placeholder="Adress"
          autoComplete="address-line1"
        />
        <Form.Control
          type="text"
          name="address.zip"
          {...formValid.address?.zip}
          ref={register({ required: true })}
          placeholder="Postkod"
          autoComplete="postal-code"
        />
        <Form.Control
          type="text"
          name="address.town"
          {...formValid.address?.town}
          ref={register({ required: true })}
          placeholder="Ort"
          autoComplete="address-level2"
        />
        <div>{children}</div>
      </div>
    </>
  );
};
export default DirectPurchaseContactInformation;
