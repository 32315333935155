import React from 'react'
import { Switch, Route, Link } from "react-router-dom"
import UserList from './UserList'
import UserDetails from './UserDetails'

import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import MyHelmet from '../../../components/MyHelmet'

export default ({ match }) => {
  let history = useHistory();
  return (
    <>
      <MyHelmet title="Användare" />
      <h1>Användare</h1>

      <Switch>
        <Route exact path={`${match.path}`}>
          <div className="mb-2">
            <Link to={`${match.path}/create`} className="mb-4">
              <Button variant="success" size="sm">
                <FontAwesomeIcon icon={faPlus} />{' '}Skapa
            </Button>
            </Link>
          </div>
          <UserList onRowSelected={(id) => history.push(`${match.path}/${id}`)} />
        </Route>

        <Route path={`${match.path}/create`} component={UserDetails} />
        <Route path={`${match.path}/:id(\\d+)`} render={props => <UserDetails userId={props.match.params.id} />} />

        <Route render={() => <h1>Not found</h1>} />
      </Switch>
    </>
  )
}
