import { apiService } from "./Api.Service"
import { CREDIT_CHECK } from "../api/endpoints";

const CheckCredit = async (ssn) => {
  return await apiService.Post(CREDIT_CHECK, { ssn: ssn });
}



let postData = async (where, data) => {
  const response = await fetch(`/${where}`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.status === 200) return response.json();

  return {};
};

const Query = async (ssn) => {
  await postData("credit", { ssn: ssn })
};

export const creditCheckService = {
  CheckCredit,
  Query
}