import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { apiService } from '../../../_services/Api.Service';
import { Row, Col, ButtonGroup, Button, Alert, } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import MyHelmet from '../../../components/MyHelmet';
import ShortDateTime from '../../../components/ShortDateTime';
import ConfirmButton from '../../../components/ConfirmButton';
import { authenticationService } from '../../../_services/Authentication.Service';
import OrderPdfViewer from '../../../components/OrderPdfViewer';

export default () => {
  const { id } = useParams();
  var [refresh, setRefresh] = useState();
  var history = useHistory();
  const { data } = useQuery([`orders-${id}`, refresh], async () => { return apiService.Get(`/orders/${id}`) });

  function Description({ title, children }) {
    return (
      <>
        <dt className="col-sm-5">{title}</dt>
        <dd className="col-sm-7">{children}</dd>
      </>
    )
  }

  const Alerts = {
    Created: () => {
      return (
        <Alert variant="warning">
          <Alert.Heading>Ej signerad</Alert.Heading>
          <p>Denna order är ännu inte markerad som signerad. Bekräfta att avtalet är signerat så Teleco kan hantera den.</p>
        </Alert>
      )
    },
    Confirmed: () => {
      return (
        <Alert variant="info">
          <Alert.Heading>Pågående</Alert.Heading>
          <p>Teleco har mottagit ordern och processerar den.</p>
        </Alert>
      )
    },
    Annulled: () => {
      return (
        <Alert variant="danger">
          <Alert.Heading>Annulerad</Alert.Heading>
        </Alert>
      )
    },
    Completed: () => {
      return (
        <Alert variant="success">
          <Alert.Heading>Slutförd</Alert.Heading>
        </Alert>
      )
    },
  }

  async function deleteOrder() {
    await apiService.Delete(`/orders/${id}`);
    history.replace("/orders");
  }

  async function updateState(newState) {
    await apiService.Put(`/orders/${id}/updatestate?newState=${newState}`);
    setRefresh(Math.random());
  }

  return (
    <>
      <MyHelmet title={`Order - ${id}`} />

      <div className="mb-4">
        <h1>Orderdetaljer - {data?.id}</h1>

        {data && (
          <>
            {data.state === 0 && <Alerts.Created />}
            {data.state === 1 && <Alerts.Confirmed />}
            {data.state === 2 && <Alerts.Annulled />}
            {data.state === 3 && <Alerts.Completed />}
            <Row>
              <Col sm={12} lg={6}>
                <h5>Kunduppgifter</h5>
                <dl className="row">
                  <Description title="Namn">{data.fullName}</Description>
                  <Description title="Adress">{data.address}</Description>
                  <Description title="Ort">{data.town}</Description>
                  <Description title="Personnummer">{data.ssn}</Description>
                  <Description title="Kontaktnummer">{data.contactNumber}</Description>
                  <Description title="Mailadress">{data.contactEmail}</Description>
                </dl>
              </Col>

              <Col sm={12} lg={6}>
                <h5>Abonnemang</h5>
                <dl className="row">
                  <Description title="Namn">{data.subscriptionName}</Description>
                  <Description title="Månadskostnad">{data.subscriptionMonthlyFee} kr</Description>
                  <Description title="Period">{data.subscriptionPeriod} månader</Description>
                  <Description title="Datapaket">{data.subscriptionDataPackage} GB</Description>
                  <Description title="Trafik">{data.subscriptionTraffic}</Description>
                </dl>
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={6}>
                <h5>Förhöjd avgift</h5>
                <dl className="row">
                  <Description title="Avgift">{data.abo} kr</Description>
                  <Description title="Produkter">{data.hardware}</Description>
                </dl>
              </Col>
              <Col sm={12} lg={6}>
                <h5>Leveransinformation</h5>
                <dl className="row">
                  <Description title="Önskat NP datum"><ShortDateTime date={data.npDate} /></Description>
                  <Description title="SIMICC">{data.iccSuffix}</Description>
                  <Description title="Nummber att flytta">{data.npmsisdn}</Description>
                </dl>
              </Col>
            </Row>

            <h5>Attestat</h5>
            <dl className="row">
              <dt className="col-sm-2">Återförsäljare</dt>
              <dd className="col-sm-10">{data.reseller}</dd>
              <dt className="col-sm-2">Namn</dt>
              <dd className="col-sm-10">{data.notarizeUserName}</dd>
              <dt className="col-sm-2">Kommentar</dt>
              <dd className="col-sm-10">{data.comment}</dd>
            </dl>

            <Row>
              <Col>
                <div>Skapad <ShortDateTime date={data.createdDate} time /></div>
              </Col>
            </Row>

            <div className="mb-4">
              <ButtonGroup aria-label="Order status">
                <Button onClick={() => { updateState(1); }} size="sm" variant="success" disabled={data.state !== 0}>Bekräfta</Button>
                <Button onClick={() => { updateState(2); }} size="sm" variant="danger" disabled={data.state === 2 || data.state === 3}>Annulera</Button>
                <Button onClick={() => { updateState(3); }} size="sm" variant="primary" disabled={data.state !== 1 || authenticationService.isAdmin === false}>Slutför</Button>
              </ButtonGroup>

              {authenticationService.isAdmin &&
                <ConfirmButton className="float-right" size="sm" onClick={async () => await deleteOrder()} />
              }
            </div>

          </>
        )}
      </div>

      <OrderPdfViewer orderId={id} />
    </>
  )
}