import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { Radio, RadioGroup } from '../radio/TecoRadio';

import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faSimCard } from '@fortawesome/free-solid-svg-icons';
import DirectPurchaseForm from './direct/DirectPurchaseForm';
import SubscriptionPurchaseForm from './subscription/SubscriptionPurchaseForm';
import ShopContext from './ShopContext';
import { TelecoHeader, ToNumberString } from '../../../components/TelecoShared';

const purchaseChoice = {
  direct: 'direct',
  subscritpion: 'subscription',
};

const ProductPurchaseFormV2 = () => {
  const [choice, setChoice] = useState();

  const [state] = useContext(ShopContext);
  const product = state.product;

  const originalPriceSpan = (
    <span className="originalPriceSpan">{ToNumberString(Math.floor(product.price / 24))} kr/mån</span>
  );

  const mothlyCostSpan = (
    <>
      {ToNumberString(product.abo)} kr/mån{originalPriceSpan}
    </>
  );

  const purchasePriceSpan = (
    <>
      {ToNumberString(product.price)} kr
      {product.listPrice ? <span className="originalPriceSpan">{ToNumberString(product.listPrice)} kr</span> : <></>}
    </>
  );

  return (
    <>
      <div className="agreementChoice">
        <TelecoHeader text="Välj betalsätt" />
        <RadioGroup
          name={'purchaseType'}
          onChange={(event) => setChoice(event.target.value)}
          className={cx({ chosen: choice })}
        >
          <Radio
            icon={faCreditCard}
            label="Betala med kort"
            value={purchaseChoice.direct}
            right={purchasePriceSpan}
            checked={choice === purchaseChoice.direct}
          />
          <Radio
            icon={faSimCard}
            label="Delbetala m. abonnemang"
            value={purchaseChoice.subscritpion}
            right={mothlyCostSpan}
            checked={choice === purchaseChoice.subscritpion}
          />
        </RadioGroup>

        {choice !== purchaseChoice.subscritpion && (
          <div>
            <strong>Välj delbetalning och få rabatt på telefonen</strong>
            <p>
              Du kan välja att betala direkt med kort eller delbetalning med ett abonnemang från Teleco. Abonnemanget är
              alltid bundet under tiden för delbetalningen och det tillkommer en avgift på 29 kr för pappersfaktura,
              autogiro och e-faktura är gratis.
            </p>
          </div>
        )}
      </div>

      {choice === purchaseChoice.direct && <DirectPurchaseForm product={product} />}
      {choice === purchaseChoice.subscritpion && <SubscriptionPurchaseForm product={product} />}
    </>
  );
};

export default ProductPurchaseFormV2;
