import React from 'react'
import { apiService } from '../_services/Api.Service';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import printJS from 'print-js';

export default ({ url, name, direct }) => {
  // const [url, setUrl] = useState();

  // useEffect(() => {
  //   getPdfDownloadLink();
  // }, []);

  async function getLocalDataUrl() {
    const { data } = await apiService.GetFile(url);
    const localUrl = window.URL.createObjectURL(data)
    return { data, url: localUrl };
  }

  const revokeObjectURL = (url) => window.URL.revokeObjectURL(url);

  async function download() {
    const { url } = await getLocalDataUrl();
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    revokeObjectURL(url);
  }

  async function print() {
    const { url } = await getLocalDataUrl();

    printJS(url);
  }

  const downloadTooltip = (props) => <Tooltip {...props}>Ladda ner</Tooltip>;
  const printTooltip = (props) => <Tooltip {...props}>Skriv ut</Tooltip>;

  if (direct) {
    return <a href="#" onClick={download}>Ladda ner avtal</a> // <button className="btnLink" onClick={download}>{name}</button>
  }

  return (
    <>
      <OverlayTrigger delay={{ show: 200, hide: 0 }} overlay={downloadTooltip}>
        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faFileDownload} onClick={download} className="mr-2" />
      </OverlayTrigger>
      <OverlayTrigger delay={{ show: 200, hide: 0 }} overlay={printTooltip}>
        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faPrint} onClick={print} />
      </OverlayTrigger>
    </>
  )
}