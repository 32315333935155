import React, { useState } from "react";


export default React.memo(({ id, onChange, children, defaultChecked = false }) => {
    const [checked, setChecked] = useState(defaultChecked);

    function onCheckChanged(e) {
        let val = e.target.checked;
        setChecked(val);
        onChange(val);
    }

    return (
        <div className="form-check" onChange={onCheckChanged}>
            <input type="checkbox" className="form-check-input" id={id} defaultChecked={checked} />
            <label className="form-check-label" htmlFor={id}>{children}</label>
        </div>
    )
});

