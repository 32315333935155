import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Spinner } from 'react-bootstrap';

import { shopService } from '../../_services/Shop.Service';
import './ProductDetails.scss';
import ProductPurchaseFormV2 from './ProductPurchaseFormV2';
import ShopContext from './ShopContext';
import PdfDownloadLink from '../../../components/PdfDownloadLink';
import { TelecoHeader } from '../../../components/TelecoShared';

const CompletedDetails = () => {
  const [shopState] = useContext(ShopContext);

  if (shopState.error) {
    return (
      <>
        <TelecoHeader text="Något gick fel!" />
        <ul>
          <li>{shopState.error}</li>
        </ul>
      </>
    );
  }

  const dlLink = (
    <PdfDownloadLink url={`/orders/${shopState.data.orderId}/pdf`} name={`${shopState.data.orderId}.pdf`} direct />
  );

  return (
    <>
      <div>
        <TelecoHeader text="Tack för din beställning" />
        <p>
          Har du beställt en telefon så kommer du inom kort får en orderbekräftelse med leveransinformation samt en
          spårningslänk till ditt paket.
        </p>
        <p>
          Har du några frågor eller funderingar är du varmt välkommen att kontakta{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://www.teleco.se/kundtjanst/">
            vår kundtjänst
          </a>
          .
        </p>
        <p>{shopState.data.ptype === 'subscription' && dlLink}</p>
      </div>
    </>
  );
};

const ProductDetails = () => {
  const { id } = useParams();
  const shopStateObject = React.useState({ completed: false, error: null });
  const [shopState, setShopState] = shopStateObject;

  // load the product
  const { data: product, isLoading } = useQuery([`product-${id}`], async () => {
    return await shopService.get(id);
  });

  useEffect(() => {
    setShopState({ ...shopState, product: product });
  }, [setShopState, product]);

  if (isLoading || !shopState.product) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 50 }}>
        <Spinner animation="border" role="status" size="lg" />
      </div>
    );
  }

  return (
    <>
      <ShopContext.Provider value={shopStateObject}>
        {!shopState.completed && <ProductPurchaseFormV2 />}
        {shopState.completed && <CompletedDetails />}
      </ShopContext.Provider>
    </>
  );
};

export default ProductDetails;
