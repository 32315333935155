import React from 'react';
import './App.scss';
import ShopRoutes from './components/shop/ShopRoutes';
import "bootstrap/dist/css/bootstrap.min.css";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ReCAPTCHAWrapper from './components/auth/ReCAPTCHAWrapper';

export const recaptchaRef = React.createRef();

function App() {
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <ReCAPTCHAWrapper ref={recaptchaRef} />
        <ShopRoutes />
      </div>
    </Elements>
  );
}



export default App;
