import React from "react";
import Navigation from "./Navigation";
import Container from "react-bootstrap/Container";

export default ({ children, ...props }) => {
  return (
    <div>
      <Navigation />
      <Container className="mt-4" {...props}>{children}</Container>
    </div>
  );
};
