import React, { useCallback, useEffect, useState } from "react"
import { Row, Col, Button, Tabs, Tab } from "react-bootstrap"
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewProductForm from "../../../components/shop/NewProductForm";
import ProductListView from "../../../components/shop/ProductListView";

const ProductContainer = React.memo(() => {
    const [newProduct, setNewProduct] = useState();

    let currentView;

    if (newProduct >= 0)
        currentView = <NewProductForm productId={newProduct} onCreated={() => setNewProduct(-1)} onCancel={() => setNewProduct(-1)} />;
    else
        currentView = <ProductListView onEdit={(productId) => setNewProduct(productId)} />;

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <h4 style={{ float: "left" }}>Hårdvara</h4>
                        <span style={{ float: "right" }}>
                            <Button variant="link" onClick={() => setNewProduct(0)}><FontAwesomeIcon icon={faPlus} /></Button>
                        </span>
                    </Col>
                </Row>
                {currentView}
            </Col>
        </Row>
    )
});

export default () => {
    const [shopPreviewVisible, setShopPreviewVisible] = useState(false);
    const [key, setKey] = useState('admin');

    const ur = React.createRef();

    useEffect(() => {
        var interval = setInterval(() => {
            let frame = document.getElementById("shopframe");
            if (frame && frame.contentWindow.document.documentElement)
                frame.style.height = frame.contentWindow.document.documentElement.scrollHeight + 'px';
        }, 20);

        return function () {
            clearInterval(interval);
        }
    }, [])

    return (
        <>
            <Tabs activeKey={key} onSelect={(k) => { setKey(k); setShopPreviewVisible(k === "preview"); }}>
                <Tab eventKey="admin" title="Admin">
                    <h1>Shop admin</h1>
                    <ProductContainer />
                </Tab>
                <Tab eventKey="preview" title="Preview">
                    {shopPreviewVisible &&
                        // <ShopApp />
                        <iframe
                            id="shopframe"
                            frameBorder="0"
                            seamless
                            title="Teleco Shop"
                            style={{ width: "100%", marginTop: 20 }}
                            src="/shop"
                            ref={ur}
                        />
                    }
                </Tab>
            </Tabs>
        </>
    )
}