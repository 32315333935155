import React from 'react'

export default ({ date, time }) => {
  if (date === null) return <></>;

  let ld = new Date(date);
  let locale = 'sv-SE'
  var localDate = ld.toLocaleDateString(locale);
  var localTime = ld.toLocaleTimeString(locale);

  return localDate + (time ? ' ' + localTime : '');
}