import React, { useContext, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import PdfDownloadLink from '../../../../components/PdfDownloadLink';
import { TelecoHeader } from '../../../../components/TelecoShared';
import ShopContext from '../ShopContext';
import DirectPurchaseContactInformation from './DirectPurchaseContactInformation';
import StripeCardPayment from './StripeCardPayment';

function toNumberString(input) {
  return parseFloat(input).toLocaleString('se').replaceAll(',', ' ');
}

const OrderPurchaseConfirmation = ({ onPaymentCompleted, readyToPay, ...props }) => {
  const [paymentInitiated, setPaymentInitiated] = useState();
  const [paymentSucceeded, setPaymentSucceeded] = useState();

  const { formState } = useFormContext();
  const { isValid: submitFormIsValid } = formState;

  if (paymentSucceeded) {
    return <></>; //<span>Betalningen lyckades (formuläret ska visa något annat, detta är bara text att det gick ok!)</span>
  }

  async function onPaymentSucceeded(orderResult) {
    setPaymentInitiated(false);
    setPaymentSucceeded(true);
    onPaymentCompleted(orderResult);
  }

  async function onPaymentError() {}

  async function onPaymentProcessing() {}

  if (paymentInitiated) {
    return (
      <StripeCardPayment
        {...props}
        onProcessing={onPaymentProcessing}
        onSucceeded={onPaymentSucceeded}
        onError={onPaymentError}
      />
    );
  }

  return (
    <>
      <button className="shopBtn block" onClick={() => setPaymentInitiated(true)} disabled={!submitFormIsValid}>
        Betala med kort {toNumberString(props.product.price)} kr
      </button>
    </>
  );
};

const DirectPurchaseForm = ({ product }) => {
  const [intent, setIntent] = useState();
  const [completedOrderId, setCompletedOrderId] = useState();

  const [shopState, setShopState] = useContext(ShopContext);

  function setCompleted(data) {
    setCompletedOrderId(data.orderId);
    setShopState({
      ...shopState,
      completed: true,
      data: { ...data, ptype: 'direct' },
    });
  }

  const formMethods = useForm({
    mode: 'onChange',
  });

  if (completedOrderId) {
    const dlLink = <PdfDownloadLink url={`/orders/${completedOrderId}/pdf`} name={`${completedOrderId}.pdf`} direct />;

    return (
      <>
        <div>
          <TelecoHeader text="Tack för din beställning" />
          <p>
            Har du beställt en telefon så kommer du inom kort får en orderbekräftelse med leveransinformation samt en
            spårningslänk till ditt paket.
          </p>
          <p>
            Har du några frågor eller funderingar är du varmt välkommen att kontakta{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.teleco.se/kundtjanst/">
              vår kundtjänst
            </a>
            .
          </p>
          <p>{dlLink}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <DirectPurchaseContactInformation setContactInfo={setIntent}>
          <OrderPurchaseConfirmation product={product} intent={intent} onPaymentCompleted={setCompleted} />
        </DirectPurchaseContactInformation>
      </FormProvider>
    </>
  );
};

export default DirectPurchaseForm;
