import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Col, Button } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { SUBSCRIPTION } from '../../../api/endpoints';
import MyHelmet from '../../../components/MyHelmet';
import { apiService } from '../../../_services/Api.Service';
import { subscriptionService } from '../../../_services/Subscription.Service';

const BaseForm = ({ sub }) => {
  if (sub) document.title = `${sub.name} (${sub.id}) - Teleco Orderportal: `;
  else document.title = `Skapa abonnemang - Teleco Orderportal`;
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({ mode: 'onChange', defaultValues: sub });

  const saveOrUpdate = async (data) => {
    if (data.id) {
      await subscriptionService.Update(data);
      history.goBack();
    } else {
      await subscriptionService.Create(data);
      history.goBack();
    }
  };

  const removeSubscription = async () => {
    await subscriptionService.Delete(sub.id);
    history.goBack();
  };

  return (
    <>
      <MyHelmet title={`${sub?.name || 'Nytt abonnemang'}`} />
      <Form onSubmit={handleSubmit(saveOrUpdate)}>
        <Form.Row>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Abonnemangsval</Form.Label>
            <Form.Control isInvalid={errors.name} name="name" ref={register({ required: true })} />
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Trafik</Form.Label>
            <Form.Control isInvalid={errors.traffic} name="traffic" ref={register({ required: true })} />
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Månadsavgift</Form.Label>
            <Form.Control
              isInvalid={errors.monthlyFee}
              name="monthlyFee"
              ref={register({ required: true, pattern: /^[0-9]{1,}?$/ })}
            />
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Datapaket (GB)</Form.Label>
            <Form.Control
              isInvalid={errors.dataPackage}
              name="dataPackage"
              ref={register({ required: true, pattern: /^[0-9]{1,}$/ })}
            />
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Listpris</Form.Label>
            <Form.Control
              isInvalid={errors.monthlyListFee}
              name="monthlyListFee"
              ref={register({ required: false, pattern: /^[0-9]{1,}$/ })}
            />
          </Form.Group>
        </Form.Row>

        {sub && <input type="hidden" ref={register} name="id" />}
        <input type="hidden" ref={register} name="period" defaultValue="24" />

        <div>
          <Button type="submit" variant="primary">
            Spara
          </Button>{' '}
          <Button variant="secondary" onClick={history.goBack}>
            Avbryt
          </Button>
          <Button className="float-right" variant="danger" onClick={removeSubscription}>
            Ta bort
          </Button>
        </div>
      </Form>
    </>
  );
};

const EditForm = ({ id }) => {
  const { status, data, error } = useQuery(['subscription', id], async () => {
    return apiService.Get(SUBSCRIPTION(id));
  });

  return (
    <>
      {error && <div>Error! {error.message}</div>}
      {status === 'loading' && <div>Laddar ...</div>}
      {data && <BaseForm sub={data} />}
    </>
  );
};

const CreateForm = () => {
  return <BaseForm />;
};

export default () => {
  const { id } = useParams();

  return id === 'create' ? <CreateForm /> : <EditForm id={id} />;
};
