import { apiService } from "./Api.Service"
import { SUBSCRIPTION, SUBSCRIPTIONS } from "../api/endpoints";

async function Create(sub) {
  return apiService.Post(SUBSCRIPTIONS, sub)
}

async function Get() {
  return apiService.Get(SUBSCRIPTIONS);
}

async function GetById(id) {
  return apiService.Get(SUBSCRIPTION(id));
}

async function Update(sub) {
  return apiService.Put(SUBSCRIPTION(sub.id), sub)
}

async function Delete(id) {
  return apiService.Delete(SUBSCRIPTION(id))
}

export const subscriptionService = {
  Create,
  Get,
  GetById,
  Update,
  Delete
}
