export const baseUrl = process.env.REACT_APP_API_URL;

export const CREDIT_CHECK = `/credit`;

export const SUBSCRIPTIONS = `/subscriptions`;
export const SUBSCRIPTION = (subId) => `${SUBSCRIPTIONS}/${subId}`;

export const USERS_ENDPOINT = `/users`;
export const USER_ENDPOINT = (userId) => `${USERS_ENDPOINT}/${userId}`;

export const ORDERS_ENDPOINT = `/orders`;
export const ORDER_ENDPOINT = (userId) => `${USERS_ENDPOINT}/${userId}`;

export const USER_AUTH = `${USERS_ENDPOINT}/authenticate`;
export const USER_STATUS = `${USERS_ENDPOINT}/status`;
export const USER_SIGNOUT = `${USERS_ENDPOINT}/signout`;

const authentication = `${baseUrl}/auth`;
export const AUTH_TOKEN = `${authentication}/token`;
export const AUTH_STATUS = `${authentication}/status`;
export const AUTH_REFRESH = `${authentication}/refresh`;

export const STORE = `/store`
