import React, { useState, useEffect } from 'react'

// import { queryCache } from 'react-query'
// import { apiService } from '../_services/Api.Service';

export default ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      // queryCache.prefetchQuery("subscriptions", apiService.Get("/subscriptions"));
      // queryCache.prefetchQuery("orders", apiService.Get("/orders"));
      // queryCache.prefetchQuery("users", apiService.Get("/users"));
      // queryCache.prefetchQuery("blacklist", apiService.Get("/blacklist"));

      const sleep = m => new Promise(r => setTimeout(r, m))

      await sleep(1000);

      setIsLoading(false);
    }
    fetchData();
  }, []);

  const Loader = () => {
    return (
      <div>
        Laddar ...
      </div>
    )
  }

  return isLoading ? <Loader /> : <>{children}</>
}